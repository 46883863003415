import React, { useEffect, useState } from "react";
import formatMoney from "../helpers/formatMoney.js";
import EColors, { EColorsHex } from "../enums/EColors";
import ESizes from "../enums/ESizes.js";

const ProductSelectModal = ({ 
  product,
  onOk,
}) => {
  const sizes = [product?.Age];
  const colors = Object.keys(EColors);

  const [colorSelected, setColorSelected] = useState(null);
  const [sizeSelected, setSizeSelected] = useState(null);

  const [currentImage, setCurrentImage] = useState(null);

  const price = Number(product?.Price);
  const allowAddToCart = (sizeSelected);

  useEffect(() => {
    setCurrentImage(product?.Images[0]);
    setColorSelected(null);
    setSizeSelected(product?.Age);
  }, [product]);
  
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center p-2">
          <img src={currentImage} alt="Sin imagen disponible" height={100} width={100} style={{ width: 'auto', height: '200px' }} />
        </div>
        <div className="col-12" style={{ background: '#ebebeb' }}>
          <div className="thumbnails container-fluid my-1 contenedor">
            <div className="row row justify-content-center" style={{ overflowX: 'auto',overflowY: 'hidden' }}>
              {product?.Images.map((image, index) => (
                <div className="img-thumbnails rounded px-1 mx-1 textShadow"
                  key={index} 
                  onClick={() => setCurrentImage(image)}
                  style={{ backgroundImage: `url(${image})` }}
                /> 
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row p-4">
        {/*
          <div className="col-12">
            <label className="d-block">Color</label>
            {colors.map(color => (
              <div 
                className={`
                  color-option ${color === colorSelected ? 'selected' : ''}
                  ${(product?.Design?.Colors[color] !== true) ? 'd-none' : ''}
                `}
                style={{ background: EColorsHex[color] }}
                onClick={() => setColorSelected(color)}
              />
            ))}
          </div>
        */}
        <div className="col-12">
          <label className="d-block">Descripción</label>
          <p style={{ fontSize: 14 }}>{product?.Description}</p>
        </div>
        <div className="col-12">
          <label className="d-block">Edad</label>
          {sizes.map(size => (
            <div 
              className={`talla-option ${size === sizeSelected ? 'selected' : ''}`}
              onClick={() => setSizeSelected(size)}
            >
              {size}
            </div>
          ))}
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          {product?.Id && (
            <button 
              className={`btn ${allowAddToCart ? 'btn-success' : 'btn-secondary'}`} 
              disabled={!allowAddToCart}
              onClick={() => onOk({
                id: product?.Id+'#'+colorSelected+sizeSelected,
                title: `${product.Name} para edad ${sizeSelected}`,
                description: product?.Id+colorSelected+sizeSelected,
                color: colorSelected,
                qty: 1,
                size: sizeSelected,
                design: currentImage,
                price,
                image: currentImage,
                pzs: product?.Pzs,
                clasification: 'PHYSICAL_GOODS',
              })}
            >
              <span style={{ letterSpacing: 2, fontSize: 18 }}>
                <i class="bi bi-plus-lg" /> {formatMoney(price)}
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductSelectModal;
