import React, { useEffect } from 'react';
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";
import formatMoney from '../helpers/formatMoney';
import card2Image from "../elements/img/card2.png";
import { EColorsHex } from "../enums/EColors.js"
import useCart from '../hooks/useCart';
import { productInventory } from '../services/FirebaseService';

const Cart = () => {
  const { cart, addCartItem, removeCartItem } = useCart();

  const EmptyCart = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h3 className="p-3 ">Tu carrito está vacío</h3>
            <Link to="/product" className="btn  btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> Continuar comprando
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const add = (product) => {
    addCartItem(product);
  };

  const remove = (product) => {
    removeCartItem(product);
  };

  useEffect(() => {
    if(cart?.items?.length > 0){
      cart.items.forEach(async (item) => {
        let itemId = item.id.split('#')[0];
        const productStock = await productInventory(itemId);
        const diffStock = productStock - item.qty;
        if(diffStock < 0){
          const diffStockAbsolute = Math.abs(diffStock);
          removeCartItem(item, diffStockAbsolute);
        }
      });
    }
  }, [cart?.items]);

  const ShowCart = () => {
    let subtotal = 0;
    let envio = 0;
    let totalItems = 0;

    cart?.items?.map((item) => {
      return (subtotal += item.price * item.qty);
    });

    cart?.items?.map((item) => {
      return (totalItems += item.qty);
    });

    return (
      <>
        <section className="h-100 gradient-custom">
          <div className="container py-0">
            <div className="row d-flex justify-content-center my-4">
              <div className="col-md-8">
                <div className="card mb-4 shadow">
                  <div className="card-header py-3">
                    <h5 className="mb-0">
                      Artículos
                    </h5>
                  </div>
                  <div className="card-body" style={{ background: 'rgb(233 233 233)' }}>
                    {cart?.items?.map((item) => {
                      return (
                        <div>
                          {item.id.includes('tarjetaInversion-') ? (
                            <div
                              key={item.id}
                              className='shadow m-4 p-4 rounded rounded-4'
                              style={{
                                background: '#fff',
                                backgroundImage: `url(${card2Image})`,
                                backgroundSize: 'cover',
                                minHeight: 260,
                                alignItems: 'center',
                                display: 'grid',
                              }}
                            >
                              <div className="row d-flex align-items-center">
                                <div className="col-12 text-center">
                                  <div className="rounded" data-mdb-ripple-color="light">
                                    <div className='row text-center justify-content-center'>
                                      <div className='col-12'>
                                        <p className=" text-md-center" style={{ color: '#fff' }}>
                                          <h3>Tarjeta de Socio</h3>
                                          <span>{formatMoney(item.price * item.qty)}</span>
                                        </p>
                                        <div class="text-center btn btn-dark m-1 fontBrockers" onClick={() => remove(item)}>
                                          Remover
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              key={item.id}
                              className='shadow m-4 p-4 rounded rounded-4'
                              style={{
                                background: '#fff',
                                minHeight: 260,
                                alignItems: 'center',
                                display: 'grid',
                              }}
                            >
                              <div className="row d-flex align-items-center">
                                <div className="col-lg-4 col-md-12 text-center order-2 order-lg-1 p-4">
                                  <div className="bg-image rounded" data-mdb-ripple-color="light">
                                    <img
                                      src={item.image}
                                      alt={item.title}
                                      width={175}
                                      height={175}
                                    />
                                    <div className='row text-center justify-content-center'>
                                      <div className='col-3'>
                                        <button className="btn px-1"
                                          onClick={() => remove(item)}>
                                          <i className="fas fa-minus"></i>
                                        </button>
                                      </div>
                                      <div className='col-6'>
                                        <button className="btn px-3 disabled" style={{ border: "none" }}>
                                          <b>{item.qty} pz</b>
                                        </button>
                                      </div>
                                      <div className='col-3'>
                                        {item.qty < item.pzs && (
                                          <button 
                                            className="btn px-1"
                                            onClick={() => add(item)}
                                          >
                                            <i className="fas fa-plus"></i>
                                          </button>
                                        )}
                                      </div>
                                      <div className='col-12'>
                                        <p className=" text-md-center">
                                          <strong>
                                            <span className="text-muted">{formatMoney(item.price * item.qty)}</span>
                                          </strong>
                                        </p>
                                        {/*
                                          <div class="text-center">
                                            <Link to={'../product/' + item.id} className="btn btn-dark m-1 fontBrockers">Ver Producto</Link>
                                          </div>
                                        */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-8 col-md-12 text-center order-1 order-lg-2">
                                  <h3 className='text-center'>{item.title}</h3>
                                  <div className='row'>
                                    <div className='col-12'>
                                      <table class="table tabla-product-desc">
                                        <tbody>
                                          <tr>
                                            <th scope="row">Edad:</th>
                                            <td>
                                              <div className="talla-option-fixed">
                                                {item.size}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <th scope="row">Diseño:</th>
                                            <td>
                                              <div
                                                className="design-option-fixed"
                                                style={{ backgroundImage: `url(${item.design})`, backgroundColor: '#000' }}
                                              />
                                            </td>
                                          </tr>
                                          {/*
                                            <tr>
                                              <th scope="row">Color:</th>
                                              <td>
                                                <div className="color-option-fixed" style={{ background: EColorsHex[item.color] }} />
                                              </td>
                                            </tr>
                                          */}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4 shadow">
                  <div className="card-header py-3 bg-light">
                    <h5 className="mb-0">Resumen</h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        Productos ({totalItems})<span>{formatMoney(Math.round(subtotal))}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                        Costo de envío
                        <span>--.--</span>
                      </li>
                      <div style={{ background: "rgb(233, 233, 233)" }} className=" mb-4">
                        <div className="card-body" style={{fontSize:"12px",padding:"0px 10px"}}>
                          <ul className="list-group list-group-flush">
                            <li style={{ background: "rgb(233, 233, 233)" }} className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                              Tiempo de envio estimado: <strong style={{marginRight:"0px",  fontWeight:"bold"}}>10 dias hábiles</strong>
                            </li>
                            <li style={{ background: "rgb(233, 233, 233)" }} className="list-group-item d-flex justify-content-between align-items-center px-0">
                              Envio gratis a partir de: <strong style={{marginRight:"0px",  fontWeight:"bold"}}>{formatMoney(800)}</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr className='mb-0'></hr>
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                        <div>
                          <strong>Total:</strong>
                        </div>
                        <span>
                          <strong>{formatMoney(Math.round(subtotal + envio))}</strong>
                        </span>
                      </li>
                    </ul>
                    <div className="text-center">
                      <Link
                        to="/checkout"
                        className="btn btn-warning btn-block m-auto"
                        style={{ fontWeight: '600' }}
                      >
                        Comprar carrito
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Mi compra</h1>
        <hr />
        {cart?.items?.length > 0 ? <ShowCart /> : <EmptyCart />}
      </div>
      <Footer />
    </>
  );
};

export default Cart;
