import "./lib/animate/animate.min.css";
import "./css/style.css";
//import "./lib/waypoints/waypoints.min.js";
import { Navbar, Footer } from "../../components";
import Home4 from "../../elements/img/Home4.jpg";
import Home7 from "../../elements/img/HomeNina.jpeg";
import Home8 from "../../elements/img/HomeUnisex.jpg";
import Home9 from "../../elements/img/HomeNino.jpg";
import grandchildren1 from "../../elements/img/grandchildrens1.webp";
import grandchildren3 from "../../elements/img/grandchildrens3.png";
import grandchildren4 from "../../elements/img/grandchildrens2.webp";
import React, { useEffect, useState } from 'react'

import HomeVideo from "../../elements/img/Video.mp4";
import ECategories from "../../enums/ECategories.js";

const HomePage = () => {
  const [esDispositivoMovil, setEsDispositivoMovil] = useState(false);
  useEffect(() => {
    const verificarEsMovil = () => {
      setEsDispositivoMovil(window.innerWidth < 768);
    };

    window.addEventListener('resize', verificarEsMovil);

    verificarEsMovil();

    return () => {
      window.removeEventListener('resize', verificarEsMovil);
    };
  }, []);
  const goToProducts = (category = null) => {
    let url = '/product';

    if (category) {
      url = url + '?category=' + category;
    }

    window.location.href = window.location.origin + url;
  };

  const goTo = () => {
    document.getElementById("jump_to_this_location").scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <>
      <Navbar />

      {esDispositivoMovil ? (
        <div className="video-background-holder" style={{ overflow: 'hidden' }} >
          <div className="video-background-overlay"></div>
          <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" >
            <source src={HomeVideo} type="video/mp4" />
          </video>
          <div className="video-background-content container">
            <div className="container d-flex">
              <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="facility-item" style={{ marginTop: '5%' }}>
                      <div className="facility-icon bg-primary">
                        <i className="bi bi-emoji-grin fa-3x text-primary"></i>
                      </div>
                      <div className="facility-text bg-primary">
                        <h3 className="text-primary mb-3">Divertida</h3>
                        <p className="mb-0">
                          ¡Viste a tus pequeños con diversión! Nuestra ropa para niños
                          es colorida, alegre y llena de energía. Desde estampados
                          juguetones hasta cortes cómodos
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="facility-item">
                      <div className="facility-icon bg-success">
                        <i className="bi bi-heart fa-3x text-success"></i>
                      </div>
                      <div className="facility-text bg-success">
                        <h3 className="text-success mb-3">Cómoda</h3>
                        <p className="mb-0">
                          Descubre la comodidad elevada con nuestra línea de ropa para
                          niños. Desde suaves camisetas hasta pantalones cómodos y
                          divertidos{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="facility-item">
                      <div className="facility-icon bg-warning">
                        <i className="bi bi-umbrella fa-3x text-warning"></i>
                      </div>
                      <div className="facility-text bg-warning">
                        <h3 className="text-warning mb-3">Elegante</h3>
                        <p className="mb-0">
                          Experimenta la elegancia en cada detalle. Nuestra ropa para
                          niños combina estilo y comodidad, creando un look chic y
                          refinado para los más pequeños{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="facility-item">
                      <div className="facility-icon bg-info">
                        <i className="fa fa-chalkboard-teacher fa-3x text-info"></i>
                      </div>
                      <div className="facility-text bg-info">
                        <h3 className="text-info mb-3">Moderna</h3>
                        <p className="mb-0">
                          Nuestra ropa para niños fusiona tendencias modernas con la
                          comodidad esencial. Dale a tus pequeños un estilo
                          contemporáneo que los haga destacar
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>

          </div>
        </div>
      ) : (
        <div className="video-background-holder" style={{ overflow: 'hidden' }} >
          <div className="video-background-overlay"></div>
          <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
            <source src={HomeVideo} type="video/mp4" />
          </video>
          <div className="video-background-content container">
            <div className="container d-flex">
              <div className="row g-4  mt-4">
                <div className="col-lg-3 col-sm-6 col-9 zoom-in my-4" data-wow-delay="0.1s" onClick={() => goTo()}>
                  <div className="facility-item">
                    <div className="facility-icon bg-primary">
                      <i className="bi bi-emoji-grin fa-3x text-primary"></i>
                    </div>
                    <div className="facility-text bg-primary">
                      <h3 className="text-primary mb-3">Divertida</h3>
                      <p className="mb-0">
                        ¡Viste a tus pequeños con diversión! Nuestra ropa para niños
                        es colorida, alegre y llena de energía. Desde estampados
                        juguetones hasta cortes cómodos
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-9 zoom-in my-4" data-wow-delay="0.3s" onClick={() => goTo()}>
                  <div className="facility-item">
                    <div className="facility-icon bg-success">
                      <i className="bi bi-heart fa-3x text-success"></i>
                    </div>
                    <div className="facility-text bg-success">
                      <h3 className="text-success mb-3">Cómoda</h3>
                      <p className="mb-0">
                        Descubre la comodidad elevada con nuestra línea de ropa para
                        niños. Desde suaves camisetas hasta pantalones cómodos y
                        divertidos{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-9 zoom-in my-4" data-wow-delay="0.5s" onClick={() => goTo()}>
                  <div className="facility-item">
                    <div className="facility-icon bg-warning">
                      <i className="bi bi-umbrella fa-3x text-warning"></i>
                    </div>
                    <div className="facility-text bg-warning">
                      <h3 className="text-warning mb-3">Elegante</h3>
                      <p className="mb-0">
                        Experimenta la elegancia en cada detalle. Nuestra ropa para
                        niños combina estilo y comodidad, creando un look chic y
                        refinado para los más pequeños{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-9 zoom-in my-4" data-wow-delay="0.7s" onClick={() => goTo()}>
                  <div className="facility-item">
                    <div className="facility-icon bg-info">
                      <i className="fa fa-chalkboard-teacher fa-3x text-info"></i>
                    </div>
                    <div className="facility-text bg-info">
                      <h3 className="text-info mb-3">Moderna</h3>
                      <p className="mb-0">
                        Nuestra ropa para niños fusiona tendencias modernas con la
                        comodidad esencial. Dale a tus pequeños un estilo
                        contemporáneo que los haga destacar
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      )}



      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 class="mb-4" style={{ marginTop: '40px' }}>
                Trapitos de Luca: Donde la Diversión se Convierte en Estilo
              </h1>
              <p>
                Bienvenido a Trapitos de Luca, donde la moda para niños cobra
                vida con alegría y estilo. Nuestra colección fusiona la
                comodidad esencial con la diversión de la infancia, creando un
                armario vibrante y lleno de energía para los más pequeños.
              </p>
              <p class="mb-4">
                Desde divertidos estampados hasta cortes cómodos, cada prenda es
                una expresión de la felicidad y la vitalidad que los niños
                llevan consigo. Descubre la magia de vestir a tus pequeños con
                Trapitos de Luca, donde cada prenda cuenta una historia colorida
                y llena de risas.
              </p>
              <div class="row g-4 align-items-center">
                <div class="col-sm-6">
                  <a class="btn btn-primary rounded-pill py-3 px-5" href="/about">
                    Acerca de nosotros
                  </a>
                </div>
                <div class="col-sm-6"></div>
              </div>
            </div>
            <div class="col-lg-6 about-img wow fadeInUp " data-wow-delay="0.5s">
              <div class="row">
                <div class="col-12 text-center" style={{ marginTop: '1px' }}>
                  <img
                    class="img-fluid w-75 rounded-circle bg-light p-3"
                    src={grandchildren3}
                    alt="Niños 1"
                    height='auto'
                    width='auto'
                    style={{ maxWidth: '60%' }}
                  />
                </div>
                <div
                  class="col-6 text-start p-0"
                  style={{ marginTop: "-150px" }}
                >
                  <img
                    class="img-fluid w-100 rounded-circle bg-light p-3"
                    src={grandchildren4}
                    alt="Niños 2"
                    height='auto'
                    width='auto'
                  />
                </div>
                <div class="col-6 text-end p-0" style={{ marginTop: "-150px" }}>
                  <img
                    class="img-fluid w-100 rounded-circle bg-light p-3"
                    src={grandchildren1}
                    alt="Niños 3"
                    height='auto'
                    width='auto'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="bg-light rounded">
            <div class="row g-0">
              <div
                class="col-lg-6 wow fadeIn"
                data-wow-delay="0.1s"
                style={{ minHeight: "400px" }}
              >
                <div class="position-relative h-100">
                  <img
                    class="position-absolute w-100 h-100 rounded"
                    src={Home4}
                    style={{ objectFit: "cover", height: '468px', width: '677px' }}
                    alt="Conoce nuestros Estilos"
                  />
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div class="h-100 d-flex flex-column justify-content-center p-5">
                  <h1 class="mb-4">
                    Conoce Nuestros Estilos Únicos: Trapitos de Luca
                  </h1>
                  <p class="mb-4">
                    En Trapitos de Luca, te invitamos a descubrir la magia de la
                    moda infantil con nuestros estilos únicos. Cada prenda es
                    una expresión de la individualidad y la diversión que
                    definirá la personalidad de tus pequeños. Desde colores
                    vibrantes hasta detalles encantadores, nuestras colecciones
                    están diseñadas para iluminar el guardarropa de los más
                    pequeños con un toque especial. Explora la creatividad de la
                    infancia a través de nuestras prendas, donde la comodidad y
                    el estilo se unen para crear un universo de posibilidades.
                    **¡Haz clic aquí para descubrir nuestros productos y llevar
                    la moda única de Trapitos de Luca a tu hogar!**
                  </p>
                  <a class="btn btn-primary py-3 px-5" href="/product">
                    Explorar Ahora <i class="fa fa-arrow-right ms-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
        <div class="container-xxl py-5">
          <div class="container">
            <div class="bg-light rounded">
              <div class="row g-0">
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                  <div class="h-100 d-flex flex-column justify-content-center p-5">
                    <h1 class="mb-4">Diseños personalizados</h1>
                    <form>
                      <div class="row g-3">
                        <div class="col-sm-6">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control border-0"
                              id="gname"
                              placeholder="Gurdian Name"
                            />
                            <label for="gname">Nombre</label>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-floating">
                            <input
                              type="email"
                              class="form-control border-0"
                              id="gmail"
                              placeholder="Gurdian Email"
                            />
                            <label for="gname">Apellidos</label>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control border-0"
                              id="cname"
                              placeholder="Child Name"
                            />
                            <label for="Email">Email</label>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control border-0"
                              id="cage"
                              placeholder="Child Age"
                            />
                            <label for="cellphone">Telefono</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-floating">
                            <textarea
                              class="form-control border-0"
                              placeholder="Leave a message here"
                              id="message"
                              style={{ height: "100px" }}
                            ></textarea>
                            <label for="message">Especificaciones</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <button
                            class="btn btn-primary w-100 py-3"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  class="col-lg-6 wow fadeIn"
                  data-wow-delay="0.5s"
                  style={{ minHeight: "400px" }}
                >
                  <div class="position-relative h-100">
                    <img
                      class="position-absolute w-100 h-100 rounded"
                      src={Home6}
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      */}
      <div class="container-fluid py-5" style={{ background: '#f1f1f1' }} id="jump_to_this_location">
        <div class="row g-4">
          <div class="col-lg-4 col-md-6" data-wow-delay="0.3s">
            <div
              class="team-item position-relative zoom-in"
              style={{ textAlign: "-webkit-center", backgroundImage: "url" }}
              onClick={() => goToProducts(ECategories.Boy)}
            >
              <img
                class="img-fluid rounded-circle w-75"
                src="img/team-2.jpg"
                alt=""
              />
              <div
                class="team-text"
                style={{
                  backgroundImage: `url(${Home9})`,
                  backgroundSize: "cover",
                  cursor: "pointer",
                }}
              ></div>
              <h2 class="text-center">Niño</h2>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-wow-delay="0.3s">
            <div
              class="team-item position-relative zoom-in"
              style={{ textAlign: "-webkit-center" }}
              onClick={() => goToProducts(ECategories.Unisex)}
            >
              <img
                class="img-fluid rounded-circle w-75"
                src="img/team-2.jpg"
                alt=""
              />
              <div
                class="team-text"
                style={{
                  backgroundImage: `url(${Home8})`,
                  backgroundSize: "cover",
                  cursor: "pointer",
                }}
              ></div>
              <h2 class="text-center">Unisex</h2>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-wow-delay="0.3s">
            <div
              class="team-item position-relative zoom-in"
              style={{ textAlign: "-webkit-center" }}
              onClick={() => goToProducts(ECategories.Girl)}
            >
              <img
                class="img-fluid rounded-circle w-75"
                src="img/team-2.jpg"
                alt=""
              />
              <div
                class="team-text"
                style={{
                  backgroundImage: `url(${Home7})`,
                  backgroundSize: "cover",
                  cursor: "pointer",
                }}
              ></div>
              <h2 class="text-center">Niña</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="container-xxl py-5">
        <div class="container">
          <div
            class="text-center mx-auto my-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1 class="mb-3">Comprometidos contigo</h1>
            <p>
              En Trapitos de Luca, no solo nos esforzamos por brindar productos
              que cumplan con tus expectativas, sino que también estamos
              comprometidos con ofrecer un servicio al cliente excepcional. Cada
              interacción cuenta, y agradecemos la oportunidad de servirte.
              ¡Gracias por elegirnos y formar parte de nuestra familia trapitos
              de Luca!
            </p>
          </div>
        </div>
      </div>

      {!esDispositivoMovil && (
        <div
          class="container-fluid bg-footer text-white-50 footer pt-5 mt-5 wow fadeIn"
        >
          <div class="container py-5">
            <div class="row text-black g-5">
              <div class="col-lg-4 col-md-5 me-md-4  ">
                <h3 class="text-black mb-4">Contacto</h3>
                <p class="mb-2">
                  <i class="fa fa-map-marker-alt me-3"></i>64000, Av Abraham
                  Lincoln 5654, 64117 Monterrey, N.L.
                </p>
                {/*
                                         <p class="mb-2">
                                           <i class="fa fa-phone-alt me-3"></i>+528116080923
                                         </p>
                                       */}
                <p class="mb-2">
                  <i class="fa fa-envelope me-3"></i>
                  <a
                    className="td-none"
                    href="mailto:contacto@trapitosdeluca.com"
                  >
                    <code class='text-black'>contacto@lostrapitosdeluca.com</code>
                  </a>
                </p>
                <div class="d-flex pt-2">
                  <a style={{ color: 'black' }} class="btn btn-outline-light btn-social" href="https://www.facebook.com/TAPITOSDELUCA" target="_blank">
                    <i class="bi bi-facebook fs-3"></i>
                  </a>
                  <a style={{ color: 'black' }} class="btn btn-outline-light btn-social" href="https://www.instagram.com/trapitosdeluca" target="_blank">
                    <i class="fab fa-instagram fs-3"></i>
                  </a>
                  <a style={{ color: 'black' }} class="btn btn-outline-light btn-social" href="https://www.tiktok.com/@trapitosdeluca?is_from_webapp=1&sender_device=pc" target="_blank">
                    <i class="bi bi-tiktok fs-3"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-md-5 ">
                <h3 class="text-black mb-4">Links</h3>
                <a
                  className="btn btn-link text-black"
                  href="/pagos-y-envios"
                  role="button"
                >
                  Pagos y Envios
                </a>

                <a class="btn btn-link text-black" href="/cookie-policy">
                  Política de cookies
                </a>
                
                <a class="btn btn-link text-black" href="/terms-conditions">
                  Términos y condiciones
                </a>
              </div>
            </div>
          </div>
        </div>
      )}



      <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i class="bi bi-arrow-up"></i>
      </a>
    </>
  );
};
export default HomePage;
