import React, {useState} from "react";
import { Alert } from "../components";
import { Footer, Navbar } from "../components";
import formatMoney from "../helpers/formatMoney";
import hazteSocio from "../elements/img/hazte-socio.webp";
import whatsApp from "../elements/img/whatsApp.png";
import spray from "../elements/img/spray.webp";
import cardImage from "../elements/img/card.webp";
import useAuth from '../hooks/useAuth';
import { Modal } from 'bootstrap';
import useCart from '../hooks/useCart';

const InvestPage = () => {
  const { addCartItem } = useCart();
  const { session } = useAuth();

  const [alert, setAlert] = useState(null);
  const showAlert = (type, message) => {
    setAlert({ type, message });
  };

  const [amountInvest, setAmountInvest] = useState(2500);

  const openModal = () => {
    const modal = new Modal(document.getElementById('checkoutModal'));
    modal.show();
  }
  const closeAlert = () => {
    setAlert(null);
  };

  function addInvestmentAtCart(){
    const currentDate = new Date();
    const currentDateUnix = currentDate.getTime() / 1000;

    addCartItem({
      id: `tarjetaSocio-${currentDateUnix}`,
      price: 2500,
      title: `Tarjeta Socio: ${session.id}`,
      qty: 1,
      color: 'na',
      size: 'na',
      design: 'na',
      image: [''],
      clasification: 'DIGITAL_GOODS',
      description: `Tarjeta de socio para customer con id: ${session.id}`,
    });
    document.getElementById('btnCloseModal').click();
    showAlert('success', 'Artículo agregado al carrito de compra');
    setTimeout(closeAlert, 2000);

    const modal = new Modal(document.getElementById('checkoutModal'));
    modal.hide();
  }

  const goLogin = () => {
    window.location.href = window.location.origin+'/login';
  }

  return (
    <>
      <Navbar />
      <div className="modal fade" id="checkoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div 
            className="modal-content"
            style={{ 
              padding: '10px 12px 20px', 
              backgroundImage: `url(${cardImage})`,
              backgroundSize: 'cover',
              border: 0,
              height: 480,
            }}
          >
            <div className="modal-header" style={{ border: 0 }}>
              <button
                id="btnCloseModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <h2>Cantidad a invertir</h2>
                  <div class="input-group my-4 shadow">
                    <span style={{ fontSize: 35 }} class="fontBrockers input-group-text" id="basic-addon1">$</span>
                    <input 
                      type="number" 
                      class="form-control fontBrockers" 
                      id="amountInvest"
                      min={2500} 
                      disabled
                      value={amountInvest}
                      style={{ fontSize: 35 }}
                      onChange={(e) => setAmountInvest(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className='row my-2'>
                <div className="col text-center">
                  <button 
                    id="btnCloseModal" 
                    className={`btn btn-warning shadow px-4`} 
                    data-bs-dismiss="modal"
                    onClick={() => addInvestmentAtCart()}
                    style={{ fontSize: 20, fontWeight: 600, color: '#353535' }}
                  >
                    Agregar al carrito <i class="bi bi-cart-plus-fill"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: '#ebebeb' }}>
        <div className="container shadow py-3" style={{ background: '#fff' }}>
          <div className="row align-items-center px-4">
            <div className="col-12 col-md-6 col-lg-7 mb-4">
              <div className="row justify-content-center">
                <div className="col-auto shadow rounded rounded-4" style={{ padding: '40px 30px' }}>
                  <span style={{ display: 'block', fontWeight: 600, fontSize: 45}}>¡Hazte socio!</span>
                  <span style={{ display: 'block', fontWeight: 600, fontSize: 25}}>
                    Invierte desde hoy en Leyenda Urbana
                  </span>
                  <span style={{ display: 'block', fontSize: 18}} className="text-secondary">Inversiones desde {formatMoney(2500)}</span>
                  <div>
                    <button 
                      className="btn btn-warning mt-4 rounded rounded-5 px-4" 
                      style={{ fontWeight: 600, fontSize: 20 }}
                      onClick={() => session?.id ? openModal() : goLogin()}
                    >
                      Comprar tarjeta
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <img
                alt="Card"
                src={hazteSocio}
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div className="row shadow" style={{ background: 'rgb(255 205 1)', fontSize: 35, }}>
            <div className="col-12 col-md-7 text-center my-4" style={{ alignSelf: 'center' }}>
              <b>Rendimiento</b> mensual
            </div>
            <div 
              className="col-12 col-md-5 text-center p-0 p-md-4 fontBrockers" 
              style={{ 
                alignSelf: 'center', 
                background: '#fff',
                backgroundImage: `url(${spray})`,
                backgroundSize: 'cover',
                fontSize: 70,
              }}
            >
              13 %
            </div>
          </div>
          <div className="row justify-content-center mb-4" style={{ marginTop: 40 }}>
            <div className="col-11 col-md-10 col-lg-8 shadow rounded rounded-4 p-2">
              <div className="row align-items-center pb-4 g-3">
                <div className="col-12 text-center mb-4">
                  <span style={{ display: 'block', fontWeight: 600, fontSize: 45}}>¡Es muy fácil!</span>
                  <span style={{ display: 'block', fontWeight: 600, fontSize: 25}}>
                    Estas a un click de iniciar tu negocio,<br/> contáctanos y conoce los beneficios
                  </span>
                </div>
                <div className="col-12 col-md-5 text-center text-md-end fs-6">
                  <b>
                    Contacta con un asesor: 
                  </b>
                </div>
                <div className="col-12 col-md-auto text-center">
                  <a href="https://api.whatsapp.com/send?phone=5218120112311" target="blank">
                    <div className="border border-2 rounded-pill fs-4 p-2 px-4" style={{ cursor: 'pointer' }}>
                      <img
                        src={whatsApp}
                        alt="Card"
                        width={75}
                      />
                      <label style={{ cursor: 'pointer', color: '#000' }}>
                        81 2011 2311
                      </label>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {alert && (
        <div className='w-md-50' style={{top: "50%", left: "40%", position: "fixed"}}>
          <Alert type={alert.type} message={alert.message}/>
        </div> 
      )}
    </>
  );
};

export default InvestPage;
