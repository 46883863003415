import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Footer, Navbar } from "../components";
import useAuth from '../hooks/useAuth';
import useCart from '../hooks/useCart';

const Login = () => {
  const [isLoginOnProgress, setIsLoginOnProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const { clearCart } = useCart();
  const { login, logout, session, loading: isSessionLoading } = useAuth();

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoginOnProgress(true);

    const email = document.getElementById('email').value;
    const pass = document.getElementById('pass').value;

    try {
      await login({ email, pass });
      
      window.location.href = window.location.origin+'/home';
    } catch (error) {
      setIsLoginOnProgress(false);
      setErrorMsg('Error al iniciar sesión revise sus accesos');
      console.error(error);
    }
  };

  useEffect(() => {
    if(!isSessionLoading && !isLoginOnProgress){
      if(session?.id){
        logout();
        clearCart();
      }
    }
}, [isSessionLoading]);

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Iniciar sesión</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="my-3">
                <label for="email">Correo electrónico</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  required
                />
              </div>
              <div className="my-3">
                <label for="pass">Contraseña</label>
                <input
                  type="password"
                  className="form-control"
                  id="pass"
                  required
                />
              </div>
              {isLoginOnProgress && (
                <div class="alert alert-secondary text-center" role="alert">
                  Validando credenciales
                  <div class="spinner-border mx-2" role="status" style={{ width: 15, height: 15, fontSize: 10 }}>
                    <span class="visually-hidden" />
                  </div>
                </div>  
              )}
              {errorMsg && (
                <div class="alert alert-danger text-center" role="alert">
                  {errorMsg}
                </div>
              )}
              <div className="my-3 text-center">
                <p>¿Aún no tienes cuenta? <Link to="/register" className="text-decoration-underline text-info">Regístrate aquí</Link> </p>
              </div>
              <div className="text-center">
                <button className="my-2 mx-auto btn btn-warning" type="submit" style={{ fontWeight: 600 }}>
                  Iniciar sesión
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
