import React from "react";
import { Footer, Navbar } from "../components";
const TermsConditions = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Términos y condiciones</h1>
        <h2 className="text-center">Última actualización: 13 de noviembre del 2023 </h2>

        <hr />
        
        <div className="row my-4 h-100">
          <div className="">
            <form>
              <div className="">
                <p className="my-5">
Por favor, lea detenidamente los siguientes términos y condiciones antes de utilizar el sitio web de Trapitos de Luca. Al acceder o utilizar este sitio, usted acepta estar sujeto a estos términos y condiciones. Si no está de acuerdo con alguno de los siguientes puntos, le recomendamos que no continúe utilizando el sitio.
</p>

                <h5 className="">
                   Aceptación de Términos:
                </h5>
                <p>                 
                  Al realizar una compra o acceder a nuestro sitio web, usted acepta automáticamente estos términos y condiciones, así como nuestra política de privacidad.
                </p>
               

                <h5 className="">
                Uso del Sitio:
                </h5>
                <p>                 
                Trapitos de Luca otorga permiso para acceder y utilizar el sitio con fines de compra personal. No se permite el uso no autorizado del sitio con fines comerciales.
                </p>


                <h5 className="">
                Información del Usuario:                </h5>
                <p>                 
                Al proporcionar información personal en nuestro sitio, usted garantiza que la información es precisa, completa y actualizada. Nos reservamos el derecho de rechazar el acceso a nuestro sitio o cancelar pedidos en caso de información incorrecta.
                </p>

                <h5 className="">
                Propiedad Intelectual:                </h5>
                <p>                 
                Todos los contenidos del sitio, incluyendo imágenes, logotipos, texto y diseño, son propiedad exclusiva de Trapitos de Luca y están protegidos por leyes de derechos de autor. No está permitido el uso no autorizado de estos materiales.
                </p>

                <h5 className="">
                Política de Precios:               
                 </h5>
                <p>                                 
                Nos esforzamos por proporcionar información precisa sobre precios y promociones. Sin embargo, nos reservamos el derecho de corregir cualquier error en la información de precios antes de completar una compra.
                </p>

                <h5 className="">
                Proceso de Compra:               
                 </h5>
                <p>                                             
                  Al realizar una compra, usted acepta proporcionar información precisa y completa necesaria para el procesamiento del pedido. Trapitos de Luca no se hace responsable de información incorrecta proporcionada por el usuario.
                </p>


                <p>
                  Aclaraciones y atención al teléfono whatsapp{" "}
                  <span style={{ fontWeight: "600" }}>81-20-11-23-11</span> y
                  correo electrónico:{" "}
                  <span style={{ fontWeight: "600" }}>
                  contacto@lostrapitosdeluca.com
                  </span>
                </p>


              </div>
              <p>
              Al continuar utilizando el sitio web de Trapitos de Luca, usted acepta estos términos y condiciones. Agradecemos su comprensión y cooperación.
                </p>
                <p>
¡Gracias por elegir Trapitos de Luca para vestir a sus pequeños con estilo y calidad!
                </p>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
