import React, { useEffect, useState } from 'react'

const Footer = () => {
  const [esDispositivoMovil, setEsDispositivoMovil] = useState(false);
  useEffect(() => {
    const verificarEsMovil = () => {
      setEsDispositivoMovil(window.innerWidth < 768);
    };

    window.addEventListener('resize', verificarEsMovil);

    verificarEsMovil();

    return () => {
      window.removeEventListener('resize', verificarEsMovil);
    };
  }, []);
  return (
    <>
      <footer className="mb-0 text-center navbar2">
        <div className="d-flex align-items-center justify-content-center pb-5">
          <div className="col-md-10">
            <div>
              <ul className="list-inline mb-0 mt-3" style={{ textAlign: "center" }}>
                <li className="list-inline-item">
                  <a className="text-dark p-2" href="https://www.facebook.com/TAPITOSDELUCA" target="blank">
                    <i className="bi bi-facebook" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-dark p-2" href="https://www.instagram.com/trapitosdeluca/" target="blank">
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-dark p-2" href="https://www.tiktok.com/@trapitosdeluca?is_from_webapp=1&sender_device=pc" target="blank">
                    <i className="bi bi-tiktok" />
                  </a>
                </li>
              </ul>
            </div>
            <p className="mb-1">Lunes a Sabado de 9:00am a 6:00pm
              <br />
              <a className="td-none" href="mailto:contacto@trapitosdeluca.com"  >
                <b><code>contacto@trapitosdeluca.com</code></b></a>
            </p>
            <p className=" ">
              
            {!esDispositivoMovil && (
                  <a class="btn btn-link text-black" href="/cookie-policy">
                    Política de cookies
                  </a>
                )}
             
              <a className="btn text-dark btn-floating m-1" href="/terms-conditions" role="button"
              >Terminos y condiciones
              </a>
              <a className="btn text-dark btn-floating m-1" href="/pagos-y-envios" role="button"
              >Pagos y Envios
              </a>
            </p>
            <small className="mb-3 mb-md-0">2023 | Made by Trapitos de Luca®</small>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
