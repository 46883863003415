import React, { useState } from 'react'
import { Footer, Navbar, CheckoutModal } from "../components";
import { Link } from 'react-router-dom';
import { Modal } from "bootstrap";
import useAuth from '../hooks/useAuth';

const Register = () => {
    const [modalContent, setModalContent] = useState();
    const [isSignInSuccess, setIsSignInSuccess] = useState(false);
    const [isSignInOnProgress, setIsSignInOnProgress] = useState(false);
    const { signIn } = useAuth();

    const typeModal = isSignInSuccess ? 'success' : 'error';

    const onSubmit = async (e) => {
        e.preventDefault();

        setIsSignInOnProgress(true);
        setModalContent(<>Enviando solicitud de registro </>);

        const modal = new Modal(document.getElementById('checkoutModal'));
        modal.show();

        const name = document.getElementById('name').value;
        const lastName = document.getElementById('lastName').value;
        const phone = document.getElementById('phone').value;
        const email = document.getElementById('email').value;
        const pass = document.getElementById('pass').value;

        try {
            await signIn({
                name,
                lastName,
                phone,
                email,
                pass,
            });

            setModalContent(<>Gracias por registrarte con nosotros</>);
            setIsSignInOnProgress(false);
            setIsSignInSuccess(true);
        } catch (error) {
            setIsSignInOnProgress(false);
            setModalContent(<>Error al solicitar el registro de usuario</>);
            console.log(error);
        }
    };

    return (
        <>
            <Navbar />
            <div className="modal fade" id="checkoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body" style={{ padding: '10px 12px 20px' }}>
                        <div className='row'>
                            <div className='col-12'>
                                <CheckoutModal 
                                    title={isSignInSuccess ? 'Registro completado' : 'Error'}
                                    subtitle={isSignInSuccess ? '¡Registrado!' : '¡Oh, algo salió mal!'}
                                    content={modalContent}
                                    description={isSignInSuccess ? 'Be a Legend' : 'Por favor ponte en contacto con nosotros'}
                                    type={isSignInOnProgress ? 'info' : typeModal}
                                />
                            </div>
                        </div>
                        <div className='row my-2'>
                            <div className="col text-center">
                                <button 
                                    id="btnCloseModal" 
                                    data-bs-dismiss="modal"
                                    className={`btn btn-secondary`} 
                                    onClick={() => window.location.href = window.location.origin+'/login'}
                                >
                                    Iniciar sesión
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="container my-3 py-3">
                <h1 className="text-center">Register</h1>
                <hr />
                <div className="row my-4 h-100">
                    <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
                        <form autocomplete='off' onSubmit={(e) => onSubmit(e)}>
                            <div className="form my-3">
                                <label for="Name">Nombre</label>
                                <input
                                    className="form-control"
                                    id="name"
                                    placeholder="Escribe tu nombre"
                                    required
                                />
                            </div>
                            <div className="form my-3">
                                <label for="Name">Apellido(s)</label>
                                <input
                                    className="form-control"
                                    id="lastName"
                                    placeholder="Escribe tu apellido(s)"
                                    required
                                />
                            </div>
                            <div className="form my-3">
                                <label for="Name">Teléfono</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="phone"
                                    placeholder="Número teléfonico"
                                    required
                                />
                            </div>
                            <div className="form my-3">
                                <label for="Email">Correo electrónico</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="name@example.com"
                                    required
                                />
                            </div>
                            <div className="form  my-3">
                                <label for="Password">Contraseña</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="pass"
                                    placeholder="Password"
                                    required
                                    minLength={6}
                                />
                            </div>
                            <div className="my-3 text-center">
                                <p>¿Ya tienes cuenta? <Link to="/login" className="text-decoration-underline text-info">Inicia sesión</Link> </p>
                            </div>
                            <div className="text-center">
                                <button className="my-2 mx-auto btn btn-dark" type="submit">
                                    Register
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Register