const formatMoney = (amount, currency = 'MXN') => {
  const currencyCode = currency;

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: currencyCode,
  });

  return `${formatter.format(amount)} ${currency ? currency : ''}`;
}

export default formatMoney;
