import React from "react";

const CheckoutModal = ({
  title,
  subtitle,
  content,
  description,
  type = 'info',
}) => {
  let icon = <i className="bi bi-info-circle-fill text-primary" style={{ fontSize: 50 }} />;
  let colorClass = 'primary';
  let colorBg = '#ffffff';

  switch (type) {
    case 'error':
      icon = <i className="bi bi-x-circle-fill text-danger" style={{ fontSize: 50 }} />
      colorClass = 'danger';
      colorBg = '#dc3545';
    break;

    case 'success':
      icon = <i className="bi bi-check-fill text-success" style={{ fontSize: 50 }} />
      colorClass = 'success';
      colorBg = '#198754';
    break;

    case 'info':
      icon = <i className="bi bi-info-circle-fill text-success" style={{ fontSize: 50 }} />
      colorClass = 'secondary';
      colorBg = '#ebebeb';
    break;

    default:
    break;
  }

  return (
    <div className="row justify-content-center gy-2">
      <div className={`col-12 text-center text-${colorClass} checkout-title-container`} style={{ background: colorBg }}>
        <div className="checkout-title">
          <span className="fs-3 align-middle mx-2">
            {title}
          </span>
        </div>
      </div>
      <div className="col-12 mt-4 text-center fs-4 checkout-subtitle">
        {subtitle}
      </div>
      <div className="col-12 mb-0 text-center">
        {icon}
      </div>
      <div className="col-10 text-center fs-6 px-4 py-2">
        {content}
      </div>
      {description && (
        <div className="col-12 text-small text-center checkout-description">
          {description}
        </div>
      )}
    </div>
  );
}

export default CheckoutModal;
