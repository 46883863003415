import React, { useEffect, useState } from "react";
import { Alert } from "../components";
import formatMoney from "../helpers/formatMoney.js";
import { EColors, EColorsHex } from "../enums/EColors.js";
import ESizes from "../enums/ESizes.js";
import { Footer, Navbar } from "../components";
import { getProducts } from "../services/FirebaseService";
import ECategories from "../enums/ECategories.js";
import NoPreview from '../elements/img/no-preview.png';
import useCart from '../hooks/useCart';

const CustomPage = () => {
  const { addCartItem } = useCart();

  const colors = Object.keys(EColors);
  const sizes = Object.keys(ESizes);

  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState([]);
  const [designs, setDesigns] = useState([]);
  const [categories, setCategories] = useState([]);
  
  const [categorySelected, setCategorySelected] = useState(ECategories.TShirt);
  const [colorSelected, setColorSelected] = useState(null);
  const [designSelected, setDesignSelected] = useState(null);
  const [sizeSelected, setSizeSelected] = useState(null);
  const [qtySelected, setQtySelected] = useState(1);

  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);

  const price = sizeSelected === ESizes.XXL ? Number(currentProduct?.price) + Number(currentProduct?.price*0.15) : currentProduct?.price;
  const allowAddToCart = (colorSelected && designSelected?.Id && sizeSelected);

  const [alert, setAlert] = useState(null);
  const showAlert = (type, message) => {
    setAlert({ type, message });
  };
  const closeAlert = () => {
    setAlert(null);
  };

  const addToCart = (item) => {
    addCartItem(item);
    showAlert('success', 'Artículo agregado al carrito de compra');
    setTimeout(closeAlert, 2000);
  }

  useEffect(() => {
    const productMatch = products.find(product => {
      if(product.Category !== categorySelected){
        return false;
      }

      if(product.Design.Id !== designSelected?.Id){
        return false;
      }

      return true;
    });

    if(productMatch?.Id){
      setCurrentProduct({
        id: productMatch.Id,
        price: productMatch.Price,
        images: productMatch.Images,
      });
  
      setCurrentImage(productMatch.Images[0]);
    }else {
      setCurrentProduct({
        id: 'random',
        price: 200,
        images: [],
      });
      setCurrentImage(null);
    }

    if(designSelected?.Colors[colorSelected] === false){
      setColorSelected(null);
    }
  }, [categorySelected, designSelected]);

  useEffect(() => {
    setDesignSelected(null);
  }, [categorySelected]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const productsData = await getProducts();

      const designsIds = new Set();
      const designs = [];

      for (const product of productsData) {
        if (!designsIds.has(product.Design.Id)) {
          designsIds.add(product.Design.Id);
          designs.push(product.Design);
        }
      }

      const categoriesEnum = Object.keys(ECategories);
      const categoriesOnStock = [];

      for (const category of categoriesEnum) {
        const design = designs.find(design => design.CategoryCloth[category] === true);

        if(design){
          categoriesOnStock.push(category);
        }
      }
      
      setProducts(productsData);
      setDesigns(designs);
      setCategories(categoriesOnStock);
      setCategorySelected(categoriesOnStock[0]);
      setLoading(false);
    };

    fetchData();
  }, []);
  
  return (
    <>
      <Navbar />
      {loading ? (
        <div className="container">
          <div className="row justify-container-center align-items-center" style={{ minHeight: 450 }}>
            <div className="col text-center p-4">
              <div class="spinner-border text-warning" role="status">
                LYND
              </div>
            </div>
          </div>
        </div>
      ): (
        <div className="container">
          <div className="row my-4">
            <div className="col-sm-12 col-md-6 py-4" style={{ background: '#f7f7f7' }}>
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <img 
                    src={currentImage || NoPreview} 
                    alt="Sin imagen disponible"
                    style={{ width: 'inherit', height: '300px', objectFit: 'contain' }}
                  />
                </div>
                <div className="col-12">
                  <div className="thumbnails container-fluid">
                    <div className="row row justify-content-center" style={{ overflowX: 'auto',overflowY: 'hidden' }}>
                      {currentProduct?.images.map((image, index) => (
                        <div className="img-thumbnails rounded px-1 mx-1 textShadow"
                          key={index} 
                          onClick={() => setCurrentImage(image)}
                          style={{ backgroundImage: `url(${image})` }}
                        /> 
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="row p-4 g-3">
                <div className="col-12">
                  <label className="d-block">Tipo de prenda</label>
                  {categories.map(category => (
                    <div 
                      className={`talla-option ${category === categorySelected ? 'selected' : ''}`}
                      onClick={() => setCategorySelected(category)}
                    >
                      {category}
                    </div>
                  ))}
                </div>
                <div className="col-12">
                  <label className="d-block">Talla</label>
                  {sizes.map(size => (
                    <div 
                      className={`talla-option ${size === sizeSelected ? 'selected' : ''}`}
                      onClick={() => setSizeSelected(prev => prev !== size ? size : null)}
                    >
                      {size}
                    </div>
                  ))}
                </div>
                <div className="col-12">
                  <label className="d-block">Color</label>
                  {colors.map(color => (
                    <div 
                      className={`
                        color-option ${color === colorSelected ? 'selected' : ''} 
                        ${(designSelected !== null && designSelected?.Colors[color] !== true) ? 'd-none' : ''}
                      `}
                      style={{ background: EColorsHex[color] }}
                      onClick={() => setColorSelected(prev => prev !== color ? color : null)}
                    />
                  ))}
                </div>
                <div className="col-12">
                  <label className="d-block">Diseño</label>
                  {designs.map(design => (
                    <div 
                      className={`
                        design-option ${design.Id === designSelected?.Id ? 'selected' : ''} 
                        ${design.CategoryCloth[categorySelected] !== true ? 'd-none' : ''}
                        ${(colorSelected !== null && design.Colors[colorSelected] !== true) ? 'd-none' : ''}
                      `}
                      style={{ backgroundImage: `url(${design.UrlDesign})`, backgroundColor: '#000' }}
                      onClick={() => setDesignSelected(prev => prev?.Id !== design.Id ? design : null)}
                    />
                  ))}
                </div>
                <hr />
                <div className="col-12">
                  <div className="row gy-2 gx-4">
                    <div className="col-sm-12 col-lg-6 text-center">
                      <div class="input-group input-group-lg mb-3">
                        <span class="input-group-text p-0" style={{ borderRadius: 0 }}>
                          <button 
                            className="btn btn-secondary btn-lg w-100 rounded-start" 
                            onClick={() => setQtySelected(prev => prev-1)} 
                            style={{ borderRadius: 0 }}
                            disabled={qtySelected <= 1}
                          >
                            <i className="fas fa-minus"></i>
                          </button>
                        </span>
                        <input type="text" className="form-control text-center" disabled value={`${qtySelected} pz`} />
                        <span class="input-group-text p-0">
                          <button 
                            className="btn btn-secondary btn-lg w-100 rounded-end" 
                            onClick={() => setQtySelected(prev => prev+1)} 
                            style={{ borderRadius: 0 }}
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 text-center">
                      {currentProduct?.id && (
                        <button 
                          className={`btn ${allowAddToCart ? 'btn-success' : 'btn-secondary'} btn-lg w-100`} 
                          disabled={!allowAddToCart}
                          onClick={() => addToCart({
                            id: currentProduct?.id+colorSelected+sizeSelected,
                            title: `${categorySelected} ${designSelected?.DesignName} en color ${colorSelected} talla ${sizeSelected}`,
                            description: currentProduct?.id+colorSelected+sizeSelected,
                            color: colorSelected,
                            qty: qtySelected,
                            size: sizeSelected,
                            design: designSelected?.UrlDesign,
                            price,
                            image: currentImage || NoPreview,
                            clasification: 'PHYSICAL_GOODS',
                          })}
                        >
                          <span style={{ letterSpacing: 2, fontSize: 18 }}>
                            <i class="bi bi-plus-lg" /> {formatMoney(price*qtySelected)}
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {alert && (
        <div className='w-md-50' style={{top: "50%", left: "40%", position: "fixed"}}>
          <Alert type={alert.type} message={alert.message}/>
        </div> 
      )}
      <Footer />
    </>
  );
}

export default CustomPage;
