import React, { useState } from 'react';

import { Footer, Navbar, CheckoutModal } from "../components";
import { Modal } from "bootstrap";

const ContactPage = () => {
  const [modalContent, setModalContent] = useState();
  const [isSignInSuccess, setIsSignInSuccess] = useState(false);
  const [isSignInOnProgress, setIsSignInOnProgress] = useState(false);
  const typeModal = isSignInSuccess ? 'success' : 'error';
  let isDisabled = false; //disable button Form
  let isCharging = true; //charging send comment

  const onSubmit = async (e) => {
    e.preventDefault();
    isDisabled = true;
    isCharging = true;
    setIsSignInOnProgress(true);
    setModalContent(<>“Enviando comentarios”</>);

    const modal = new Modal(document.getElementById('checkoutModal'));
    modal.show()
    try {
        await sendComment();
        isCharging = false;
        setModalContent(<>“¡Tu satisfacción es nuestro mayor objetivo! Nos encanta que te guste nuestro producto. ¡Te agradecemos tus comentarios!”</>);
        setIsSignInOnProgress(false);
        setIsSignInSuccess(true);
    } catch (error) {
      isCharging = false;

      setIsSignInOnProgress(false);
      setModalContent(<>“Error al enviar tus comentarios”</>);
    }
};
  async function sendComment(){
    try {
      const name = document.getElementById('name').value;
      const email = document.getElementById('email').value;
      const message = document.getElementById('message').value;
      await fetch(
        "https://desarrollozakamura.com/los-trapitos-de-luca/api/?action=contact",
        {
          method: "POST",
          headers: { 
            "API_KEY": '4f36725505999d31bf4f55e04c5f06679f78a633',
          },
          body: JSON.stringify({
            name: name,
            email: email,
            message: message,
        }),
        }
      ); 

    } catch (error) {
      throw error
    }
  }

  return (
    <>
      <Navbar />

      <div className="modal fade" id="checkoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body" style={{ padding: '10px 12px 20px' }}>
                        <div className='row'>
                            <div className='col-12'>
                                <CheckoutModal 
                                    title={isSignInSuccess ? 'Comentarios enviados' : isCharging?'Enviando...': 'Error'}
                                    content={modalContent}
                                    description=''
                                    type={isSignInOnProgress ? 'info' : typeModal}
                                />
                            </div>
                        </div>
                        <div className='row my-2'>
                            <div className="col text-center">
                                <button 
                                    id="btnCloseModal" 
                                    data-bs-dismiss="modal"
                                    className={`btn btn-secondary`} 
                                    onClick={() => window.location.href = window.location.origin+'/'}
                                    disabled={!isSignInSuccess}
                                    style={{ backgroundColor:'#c19ec8',borderColor:'#c19ec8' }}
                                >
                                   Aceptar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

      <div className="container my-3 py-3">
        <h1 className="text-center">Contáctanos</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form autoComplete='off' onSubmit={(e) => onSubmit(e)}>
              <div className="form my-3">
                <label htmlFor="name">Nombre</label>
                <input
                  required
                  className="form-control"
                  id="name"
                  placeholder="Introduce tu nombre"
                />
              </div>
              <div className="form my-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                  required
                />
              </div>
              <div className="form  my-3">
                <label htmlFor="message">Comentario</label>
                <textarea
                  rows={5}
                  className="form-control"
                  id="message"
                  placeholder="Introduce tus comentarios"
                  required
                />
              </div>
              <div className="text-center">
                <button
                  className="my-2 px-4 mx-auto btn btn-dark"
                  type="submit"
                  disabled={isDisabled}
                  style={{ backgroundColor:'#c19ec8',borderColor:'#c19ec8' }}
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
       
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
