import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './hooks/useAuth';
import { CartProvider } from './hooks/useCart';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import {
  Home,
  CustomPage,
  Product,
  Products,
  AboutPage,
  ContactPage,
  Cart,
  Login,
  Register,
  Checkout,
  PageNotFound,
  GuiaTallas,
  ProfilePage,
  TermsConditions,
  CookiePolicy,
} from "./pages";
import PagosEnvios from './pages/PagosEnvios';
import InvestPage from './pages/InvestPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <CartProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/product" element={<Products />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/custom" element={<CustomPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/invest" element={<InvestPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/guia-tallas" element={<GuiaTallas />} />
          <Route path="/pagos-y-envios" element={<PagosEnvios />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          
          <Route path="*" element={<Navigate to="/404" replace />} />
          <Route path="/product/*" element={<Navigate to="/404" replace />} />
          <Route path="/404" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </CartProvider>
  </AuthProvider>
);