import React from "react";
import { Footer, Navbar } from "../components";
const CookiePolicy = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Política de cookies</h1>
        <hr />
        <div className="row my-4">
          <div className="col">
            <form>
              <div>
                <h3>Cookies de Los trapitos de Luca</h3>

                <p>
                  <strong>1. Introducción</strong>
                </p>
                <p>
                  1.1 Este sitio web utiliza cookies. Al utilizar este sitio web
                  y aceptar nuestra política de cookies, acepta el uso de
                  cookies de acuerdo con los términos de esta política.
                </p>

                <p>
                  <strong>2. ¿Qué son las cookies?</strong>
                </p>
                <p>
                  2.1 Las cookies son pequeños archivos de texto que se
                  almacenan en su dispositivo (ordenador, tableta, smartphone)
                  cuando visita un sitio web. Estas cookies se utilizan para
                  mejorar la experiencia del usuario y ayudar al sitio web a
                  funcionar de manera más eficiente.
                </p>

                <p>
                  <strong>3. Tipos de cookies que utilizamos</strong>
                </p>

                <p>
                  <strong>3.1. Cookies esenciales:</strong>
                </p>
                <p>
                  Estas cookies son necesarias para el funcionamiento del sitio
                  web y no se pueden desactivar en nuestros sistemas. Por lo
                  general, solo se establecen en respuesta a acciones realizadas
                  por usted que equivalen a una solicitud de servicios, como
                  establecer sus preferencias de privacidad, iniciar sesión o
                  completar formularios. Puede configurar su navegador para
                  bloquear o alertar sobre estas cookies, pero algunas partes
                  del sitio no funcionarán. Estas cookies no almacenan ninguna
                  información personal identificable.
                </p>

                <p>
                  <strong>3.2. Cookies de rendimiento:</strong>
                </p>
                <p>
                  Estas cookies nos permiten contar las visitas y fuentes de
                  tráfico para que podamos medir y mejorar el rendimiento de
                  nuestro sitio. Nos ayudan a saber qué páginas son las más y
                  menos populares y a ver cómo los visitantes navegan por el
                  sitio. Toda la información que recopilan estas cookies es
                  agregada y, por lo tanto, anónima. Si no permite estas
                  cookies, no sabremos cuándo ha visitado nuestro sitio y no
                  podremos controlar su rendimiento.
                </p>

                <p>
                  <strong>3.3. Cookies de funcionalidad:</strong>
                </p>
                <p>
                  Estas cookies permiten que el sitio web recuerde las
                  elecciones que hace (como su nombre de usuario, idioma o la
                  región en la que se encuentra) y proporcionan características
                  mejoradas y más personales. También se pueden utilizar para
                  ofrecer servicios que ha solicitado, como ver un video o
                  comentar en un blog. La información que recopilan estas
                  cookies puede ser anónima y no rastrea su actividad de
                  navegación en otros sitios web.
                </p>

                <p>
                  <strong>3.4. Cookies de publicidad:</strong>
                </p>
                <p>
                  Estas cookies se utilizan para hacer que la publicidad sea más
                  relevante para usted y sus intereses. También se utilizan para
                  limitar la cantidad de veces que ve un anuncio y para medir la
                  eficacia de una campaña publicitaria. Por lo general, se
                  colocan por redes publicitarias con el permiso del operador
                  del sitio web. Recuerdan que ha visitado un sitio web y esta
                  información se comparte con otras organizaciones, como
                  anunciantes. Si no permite estas cookies, experimentará
                  publicidad menos dirigida.
                </p>

                <p>
                  <strong>4. Cómo controlar las cookies</strong>
                </p>
                <p>
                  4.1 Puede controlar y administrar las cookies de varias
                  maneras. Tenga en cuenta que eliminar o deshabilitar las
                  cookies puede afectar su experiencia de navegación en este
                  sitio web.
                </p>

                <p>
                  4.2. Puede configurar su navegador para aceptar o rechazar
                  todas o algunas cookies. Consulte las opciones de
                  configuración del navegador para gestionar las cookies.
                </p>

                <p>
                  <strong>5. Cambios en nuestra política de cookies</strong>
                </p>
                <p>
                  5.1 Cualquier cambio que realicemos en nuestra política de
                  cookies en el futuro se publicará en esta página. Revise esta
                  página con regularidad para estar al tanto de las
                  actualizaciones o cambios en nuestra política de cookies.
                </p>

                <p>
                  <strong>6. Contacto</strong>
                </p>
                <p>
                  6.1 Si tiene alguna pregunta sobre nuestra política de
                  cookies, puede ponerse en contacto con nosotros a través de
                  Correo:{" "}
                  <span style={{ fontWeight: "600" }}>
                    contacto@lostrapitosdeluca.com
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CookiePolicy;
