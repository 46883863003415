const ECategories = {
  TShirt: 'TShirt',
  Hoodies: 'Hoodies',
  Overshirts: 'Overshirts',
  Sets: 'Sets',
  Boy: 'Boy',
  Girl: 'Girl',
  Unisex: 'Unisex',
};

export default ECategories;
