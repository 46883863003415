"use client";

import { userLogin, userRegistration } from '../services/FirebaseService';
import React, {
  useEffect, 
  useState, 
} from 'react';

const authContext = React.createContext({});

const useAuth = () => {
  let initSession = undefined;

  const [session, setSession] = useState(initSession);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(undefined);

  const resetAuthContext = () => {
    localStorage.removeItem('session');
    setSession(undefined);
  };

  const isSessionExpired = () => {
    let isExpired = false;

    if(session?.expDate){
      const expDate = new Date(session.expDate);
      const currentDate = new Date();

      isExpired = currentDate > expDate;
    }

    return isExpired;
  };

  const checkAuth = async () => {
    setLoading(true);

    try {
      const isAuth = !isSessionExpired();

      setIsAuthenticated(isAuth);
    } catch (error) {
      setIsAuthenticated(false);
      resetAuthContext();
    } finally {
      setLoading(false);
    }
  };

  const login = async (data) => {
    setLoading(true);
    try {
      const userData = await userLogin(data);

      var expDate = new Date();
      expDate.setDate(expDate.getDate() + 1);

      const session = {
        id: userData.FId,
        name: userData.Name,
        lastName: userData.LastName,
        phone: userData.Tel,
        email: userData.Email,
        expDate: expDate,
      };

      localStorage.setItem('session', JSON.stringify(session));

      setSession(session);
      setIsAuthenticated(true);
      setAuthError(undefined);
    } catch (error) {
      console.error('error login', error);
      resetAuthContext();
      setIsAuthenticated(false);

      if(typeof error.message === 'string'){
        setAuthError(error.message);
      } else {
        setAuthError('Ocurrio un error insesperado');
      }

      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);

    setAuthError(undefined);
    resetAuthContext();
    setIsAuthenticated(false);

    setLoading(false);
  };

  const signIn = async (data) => {
    setLoading(true);

    try {
      await userRegistration(data);
      setAuthError(undefined);
    } catch (error) {
      console.error('error signIn', error);

      if(typeof error.message === 'string'){
        setAuthError(error.message);
      } else {
        setAuthError('Ocurrio un error insesperado');
      }

      throw error;
    } finally {
      setLoading(false);
    }
  };

  const cleanupErrors = () => {
    setAuthError(undefined);
  };

  useEffect(() => {
    checkAuth();
    
    if ('session' in localStorage) {
      const sessionItem = localStorage.getItem('session');
  
      if(sessionItem){
        setSession(JSON.parse(sessionItem));
      }
    }
  }, []);

  return {
    session,
    isAuthenticated,
    authError,
    loading,
    login,
    logout,
    signIn,
    isSessionExpired,
    cleanupErrors,
  };
}

export const AuthProvider = ({ children }) => {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export default function AuthConsumer() {
  return React.useContext(authContext);
}
