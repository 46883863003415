import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import Frente from "../elements/img/FrenteAmarillo.png"
import Reverso from "../elements/img/AmarilloReverso.png"
import useAuth from '../hooks/useAuth';
import formatMoney from "../helpers/formatMoney";
import { getCardsByCustomerId, getTransactionsByCustomerId } from "../services/FirebaseService";

const ProfilePage = () => {
    const { session, loading: isSessionLoading, isSessionExpired } = useAuth();
    const [cards, setCards] = useState([]);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        const getProfileData = async () => {
          try {
            if(!session?.id){
              throw new Error("Sin session id");
            }
    
            if(isSessionExpired()){
              throw new Error("Sesión expirada");
            }

            const userCards = await getCardsByCustomerId(session.id);
            setCards(userCards);

            const userTransactions = await getTransactionsByCustomerId(session.id);
            setTransactions(userTransactions);
          } catch (error) {
            window.location.href = window.location.origin+'/login';
          }
        }
    
        if(!isSessionLoading){
          getProfileData();
          return;
        }
    }, [isSessionLoading]);

    const goToLogin = () => {
        window.location.href = window.location.origin+'/login';
    }

    const goToInvest = () => {
        window.location.href = window.location.origin+'/invest';
    }
      
    return (
        <><Navbar />
            <div style={{ background: '#e7e7e7' }}>
                <div className="container">
                    <div className="row justify-content-center g-4 mt-4">
                        <div className="col-lg-6">
                            <div className="row justify-content-between px-4 mb-2">
                                <div className="col-auto text-center">
                                    <h1 className="m-0">Mi Perfil</h1>
                                </div>
                                <div className="col-auto d-flex align-items-center">
                                    <button
                                        type="button"
                                        className="p-2 btn btn-dark btn-sm"
                                        onClick={() => goToLogin()}
                                    >
                                        <i class="bi bi-person-fill-x"></i> Cerrar sesión
                                    </button>
                                </div>
                            </div>
                            <div className="card shadow border-0 mb-4 p-4">
                                <div className="card-body">
                                    <div className="row g-4 justify-content-center">
                                        <div className="col-md-6">
                                            <h5 className="fontNeue" >Nombre :</h5>
                                            <p className=" display-1 fs-6 text-secondary my-1">
                                                {session?.name}
                                            </p>
                                            <h5 className="fontNeue"> Telefono :</h5>
                                            <p className="display-1 fs-6 text-secondary my-1">
                                                {session?.phone}
                                            </p>
                                            <h5 className="fontNeue">Correo Electronico :</h5>
                                            <p className="display-1 fs-6 text-secondary my-1">
                                                {session?.email}
                                            </p>
                                        </div>
                                        <div className="col-md-6" style={{ minHeight: '250px' }}>
                                            {cards.length > 0 ? (
                                                <div class="card-container" id="card-container">
                                                    <div class="card-face front">
                                                        <img src={Frente} className="rounded-3" alt="Cara frontal de la tarjeta" width="100%" height="auto" />
                                                    </div>
                                                    <div class="card-face back">
                                                        <img src={Reverso} className="rounded-3" alt="Cara trasera de la tarjeta" width="100%" height="auto" />
                                                    </div>
                                                </div>
                                            ): (
                                                <div className="card shadow" style={{ background: '#e9e9e9' }}>
                                                    <div className="text-center p-4">
                                                        <p>Aún no tienes tu tarjeta de socio</p>
                                                        <button className='btn btn-warning' onClick={() => goToInvest()}>
                                                            Conseguir
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h1>Listado de compras</h1>
                                </div>
                                <div className="col-12" style={{ display: 'inline-box', overflow: 'auto', maxHeight: 700 }}>
                                    {transactions.length > 0 ? (
                                        transactions.map(transaction => (
                                            <div className="card shadow border-0 mb-4 rounded-4">
                                                <div className="card-body">
                                                    <div className="row justify-content-between p-4">
                                                        <div className="col-sm-12">
                                                            <div className="row g-4">
                                                                <div className="col-sm-12 text-start">
                                                                    <h5 className="fontNeue text-start">
                                                                        <b className="d-block my-2">Orden ID</b>
                                                                        #{transaction.id}
                                                                        <hr />
                                                                    </h5>
                                                                    <table style={{ width: '100%' }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Fecha de pedido:</td>
                                                                                <td className="text-secondary p-1">{transaction.creationDate}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Dirección de envío:</td>
                                                                                <td className="text-secondary p-1">
                                                                                    {transaction.shipping.address.address_line_1},
                                                                                    {transaction.shipping.address.address_line_2},
                                                                                    {transaction.shipping.address.postal_code},
                                                                                    {transaction.shipping.address.admin_area_2},
                                                                                    {transaction.shipping.address.admin_area_1}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Recibe:</td>
                                                                                <td className="text-secondary p-1">{transaction.shipping.name.full_name}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="col-12">
                                                                    <h5 className="fontNeue text-start">
                                                                        Detalles de compra:
                                                                        <hr />
                                                                    </h5>
                                                                    <table style={{ width: '100%' }}>
                                                                        <tbody>
                                                                            {transaction.items.map(item => (
                                                                                <tr>
                                                                                    <td className="text-center">{item.quantity} pz</td>
                                                                                    <td className="text-start p-4" style={{ width: '50%' }}>{item.name}</td>
                                                                                    <td className="text-center">{formatMoney(item.unit_amount.value)}</td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td colSpan={2} className="text-end fs-6">Subtotal:</td>
                                                                                <td className="text-center">{formatMoney(transaction.amount.breakdown.item_total.value)}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2} className="text-end fs-6">Envío:</td>
                                                                                <td className="text-center">{formatMoney(transaction.amount.breakdown.shipping.value)}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={3}>
                                                                                    <hr className="my-2" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2} className="text-end fs-4">Total:</td>
                                                                                <td className="text-center">{formatMoney(transaction.amount.value)}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                    ) : (
                                        <div className="card shadow border-0 mb-4 rounded-4">
                                            <div className="card-body">
                                                <div className="row justify-content-between p-4">
                                                    <div className="col-sm-12">
                                                        dd
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* <TransactionList transactions={profile.transactions} />*/}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ProfilePage;