import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import { Footer, Navbar, Alert } from "../components";
import useCart from '../hooks/useCart';

const Product = () => {
  const [alert, setAlert] = useState(null);
  const showAlert = (type, message) => {
    setAlert({ type, message });
  };
  const closeAlert = () => {
    setAlert(null);
  };

  const { addCartItem } = useCart();

  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const addProduct = (product) => {
    addCartItem(product)
    showAlert('success', 'El elemento se ha agregado al carrito.');
    setTimeout(closeAlert, 2000);
  };

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      const response = await fetch(`https://sheet.best/api/sheets/7c8c3739-c7a7-4d55-be84-7c8c053bb28f`);
      const data = await response.json();
      const idDeseado = id;
      const productoDeseado = data.find(producto => producto.id === idDeseado);
      if (productoDeseado) {
        setProduct(productoDeseado);
      } else {
        console.log(`No se encontró un producto con el ID ${idDeseado}`);
      }
      setLoading(false);
    };
    getProduct();
  }, [id]);

  const openModal = (imageTnumb) => {
    setSelectedImage(imageTnumb);
    const modal = document.getElementById("imageModal");
    modal.style.display = "block";
  };

  const closeModal = () => {
    setSelectedImage(null);
    const modal = document.getElementById("imageModal");
    modal.style.display = "none";
  };

  const Loading = () => {
    return (
      <>
        <div className="container my-5 py-2">
          <div className="row">
            <div className="col-md-6 py-3">
              <Skeleton height={400} width={400} />
            </div>
            <div className="col-md-6 py-5">
              <Skeleton height={30} width={250} />
              <Skeleton height={90} />
              <Skeleton height={40} width={70} />
              <Skeleton height={50} width={110} />
              <Skeleton height={120} />
              <Skeleton height={40} width={110} inline={true} />
              <Skeleton className="mx-3" height={40} width={110} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ShowProduct = () => {
    if (product.images && product.images.trim() !== "") {
      const imageArray = product.images.split(',');
      return (
        <>
          <div className="container my-5 py-2">
            <div className="row">
              <div className="col-md-4 col-sm-12 p-4">
                <div className="main-image">
                  <img
                    className="img-fluid textShadow rounded"
                    src={product.image}
                    alt={product.title}
                    width="400px"
                    height="400px"
                  />
                </div>
                <div className="thumbnails container-fluid my-3 contenedor">
                  <div className="row flex-nowrap" style={{ overflowX: 'auto',overflowY: 'hidden' }}>
                    {imageArray.map((imageTnumb, index) => (
                      <div className="img-thumbnails rounded px-1 mx-1 textShadow"
                        key={index} 
                        onClick={() => openModal(imageTnumb)}  
                        style={{ backgroundImage: `url(${imageTnumb})`}}
                      /> 
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-md-6">
              <h4 className="text-uppercase text-muted">{product.category}</h4>
                <h2 className="">{product.title}</h2>
                <h3 className="h5 my-4">${product.price}</h3>
                <p className="lead">{product.description}</p>
                <button 
                onClick={() => addProduct(product)}
                className="btn btn-light m-1 btn-add-cart fontBrockers"
                >Add <i className="fa fa-cart-shopping mr-1"></i>
                </button>
                <Link to="/cart" className="btn btn-dark mx-3 fontBrockers">
                 Carrito
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="container my-5 py-2">
            <div className="row">
              <div className="col-md-4 col-sm-12 p-4">
                <div className="main-image">
                  <img
                    className="img-fluid"
                    src={product.image}
                    alt={product.title}
                    width="400px"
                    height="400px"
                  />
                </div>
                <p>No hay datos adicionales para este producto.</p>
              </div>
              <div className="col-md-8 col-md-6">
                <b className="text-uppercase text-muted">{product.category}</b>
                <h2 className="">{product.title}</h2>
                <h3 className="h5 my-4">${product.price}</h3>
                <p className="lead">{product.description}</p>
                 
                <button 
                onClick={() => addProduct(product)}
                className="btn btn-light m-1 btn-add-cart fontBrockers"
                >Add <i className="fa fa-cart-shopping mr-1"></i>
                </button>
                <Link to="/cart" className="btn btn-dark mx-3 fontBrockers">
                 Carrito
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">{loading ? <Loading /> : <ShowProduct />}</div>
      </div>
      {alert && (
        <div className='w-md-50' style={{top: "50%", position: "fixed",}}>
          <Alert type={alert.type} message={alert.message}/>
        </div> 
      )}
      {/* Modal for displaying the image at full size */}
      <div id="imageModal" className="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
            <h3 className="modal-title fs-5">{product.title}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              />
            </div>
            <div className="modal-body">
              <div className="modal-body text-center ">
                <img src={selectedImage} alt={product.title} style={{maxHeight:'60vh'}} className="img-fluid rounded textShadow"/>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;
