const getCurrentDatetime = () => {
  var today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  let day = today.getDate();
  if (day < 10) {
    day = '0' + day;
  }

  let Hours = today.getHours();
  if (Hours < 10) {
    Hours = '0' + Hours;
  }

  let Minutes = today.getMinutes();
  if (Minutes < 10) {
    Minutes = '0' + Minutes;
  }

  let Seconds = today.getSeconds();
  if (Seconds < 10) {
    Seconds = '0' + Seconds;
  }

  var fechaDiagonal = day + '/' + month + '/' + year;
  let FechaCompleta = fechaDiagonal + ' ' + Hours + ':' + Minutes + ':' + Seconds;

  return FechaCompleta;
}

export default getCurrentDatetime;
