import React, { useState, useEffect } from "react";

const cartContext = React.createContext({});

const useCart = () => {
  const localStorageItemName = 'cart';
  const [cart, setCart] = useState(null);

  const initializeCart = (newCart = {}) => {
    const initialCart = { items: [] };
    const initializedCart = {...initialCart, ...newCart};

    localStorage.setItem(localStorageItemName, JSON.stringify(initializedCart));
    setCart(initializedCart);
  }

  const updateCart = (cartUpdated) => {
    localStorage.setItem(localStorageItemName, JSON.stringify(cartUpdated));
    setCart(cartUpdated);
  }

  const addCartItem = (itemToAdd, qtyToAdd = 1) => {
    if(cart){
      console.log('cart', cart);
      const itemToAddExist = cart.items.find((item) => item.id === itemToAdd.id);
      let itemsUpdated;

      if(itemToAddExist){
        itemsUpdated = cart.items.map((item) => item.id === itemToAdd.id ? { ...item, qty: (item.qty + qtyToAdd) } : item);
      }
      else{
        const qty = itemToAdd.qty || qtyToAdd;
        itemsUpdated = [ ...cart.items, { ...itemToAdd, qty}];
      }

      updateCart({ ...cart, items: itemsUpdated });

      return;
    }

    initializeCart({ items: [itemToAdd] });
  };

  const removeCartItem = (itemToRemove, qtyToRemove = 1) => {
    if(cart){
      const itemToRemoveExist = cart.items.find((item) => item.id === itemToRemove.id);
      let itemsUpdated;

      if(itemToRemoveExist.qty === 1){
        itemsUpdated = cart.items.filter((item) => item.id !== itemToRemove.id);
      }
      else{
        itemsUpdated = cart.items.map((item) => item.id === itemToRemove.id ? { ...item, qty: (item.qty - qtyToRemove) } : item);
      }

      updateCart({ ...cart, items: itemsUpdated });
    }
  };

  const clearCart = () => {
    localStorage.removeItem(localStorageItemName);
    setCart(null);
  };

  useEffect(() => {
    if (localStorageItemName in localStorage) {
      const cartItem = localStorage.getItem(localStorageItemName);
  
      if(cartItem){
        setCart(JSON.parse(cartItem));
        return;
      }
    }

    initializeCart();
  }, []);

  return {
    cart,
    addCartItem,
    removeCartItem,
    clearCart,
  };
}

export const CartProvider = ({ children }) => {
  const cart = useCart();

  return <cartContext.Provider value={cart}>{children}</cartContext.Provider>;
};

export default function CartConsumer() {
  return React.useContext(cartContext);
};
