import React, { useState, useEffect } from "react";
import { Footer, Navbar, CheckoutModal } from "../components";
import { Link } from "react-router-dom";
import formatMoney from '../helpers/formatMoney';
import { Modal } from "bootstrap";
import useAuth from '../hooks/useAuth';
import useCart from '../hooks/useCart';
import { getPostalCodes, addTransaction ,productInventory,updateProductInventory} from '../services/FirebaseService';
import card2Image from "../elements/img/card2.png";


import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const Checkout = () => {
  const { session, loading: isSessionLoading, isSessionExpired } = useAuth();
  const { cart, addCartItem, removeCartItem } = useCart();

  const [modalContent, setModalContent] = useState();
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentOnProgress, setIsPaymentOnProgress] = useState(false);
  const [shippingPrice, setShippingPrice] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [cartOutStock, setCartOutStock] = useState([]);

  const purchaseErrorMsg = "No se pudo realizar el cobro de la transacción, puedes intentar usando una tarjeta diferente o puedes ponerte en contacto con nosotros en alguno de nuestros correos o teléfono.";
  const purchaseSuccessMsg = "Se envió a tu correo electrónico información sobre la compra, puedes escribirnos o llamar a nuestros correos de contacto o número teléfonico si tienes alguna duda";
  const initialOptions = {
    clientId: 'ATyJX27u_xZFgJ5rU8LE0emlTKw0zv9tlyMfsnJd_VazvcL0Yb6qZR4mkkhpVFUuKywXKqcF5-Ld4zfG',
    //clientId: 'AR9BT0IYWLpMOErBKNyNQXtNlmXU7ryy39X-7DuYts80l0199RGABh272bzBRS-kNKTkEjHWQm06wnve',
    currency: "MXN",
  };
  const mostrarModal = async () => {
    const modal = new Modal(document.getElementById('OutStockModal'));
     modal.show();
  };

  const ocultarModal = async () => {
    const modal = new Modal(document.getElementById('OutStockModal'));
     modal.hide();
     window.location.href = window.location.origin+'/cart';

   
  };
   const add = (product) => {
    addCartItem(product);
  };

  const remove = (product) => {
    removeCartItem(product);
  };

  const validateStock= async ()=> {
    const cartOutStockAux = [];
    const arrayItems = cart.items;

    for (let index = 0; index < arrayItems.length; index++) {
      const itemOutStock = {
        ...arrayItems[index],
        isOutStock: true
      };
      let itemId = itemOutStock.id.split('#')[0];
      const productStock = await productInventory(itemId);                                   
      const diffStock = productStock - itemOutStock.qty;
     //const diffStock = 2 - 5;

      if(diffStock < 0){
        if (itemOutStock) {
          const diffStockAbsolute = Math.abs(diffStock);
          itemOutStock.qty = diffStockAbsolute;
        }
        cartOutStockAux.push(itemOutStock);
      }
    }

    if(cartOutStockAux.length > 0){
      setCartOutStock(cartOutStockAux);    

      await mostrarModal();
    }  };

  const onSubmitShippingAddress = async () => {
    const userFullName = document.getElementById('userFullName').value;
    const userEmail = document.getElementById('userEmail').value;

    const street = document.getElementById('street').value;
    const suburb = document.getElementById('suburb').value;
    const city = document.getElementById('city').value;
    const stateLocation = document.getElementById('state').value;
    const postalCode = document.getElementById('postalCode').value;
   
    setCustomerInfo({
      email: userEmail,
      name: userFullName,
    })

   

    setShippingAddress({
      type: 'SHIPPING',
      name: {
        full_name: userFullName,
      },
      address: {
        address_line_1: street,
        address_line_2: suburb,
        admin_area_2: city,
        admin_area_1: stateLocation,
        postal_code: postalCode,
        country_code: 'MX',
      },
    });

    let itemsTotalPrice = 0;

    cart?.items?.map((item) => {
      return (itemsTotalPrice += item.price * item.qty);
    });

    if(itemsTotalPrice >= 800){
      setShippingPrice(0);
      return;
    }

    const postalCodes = await getPostalCodes(postalCode);
    let shippingInSideCost = null;

    console.log('postalCodes', postalCodes);

    if(Array.isArray(postalCodes) && postalCodes.length > 0){
      shippingInSideCost = Number(postalCodes[0].CostoEnvio || null);
    }

    const shippingOutsideCost = 150;

    setShippingPrice(shippingInSideCost !== null ? shippingInSideCost : shippingOutsideCost);
  }

  useEffect(() => {
    return () => {
      const modalScreen = document.getElementsByClassName('modal-backdrop');

      if (modalScreen[0]) {
        modalScreen[0].remove();
        document.body.classList.remove('modal-open');
        document.body.removeAttribute('style');
      }
    };
  }, []); 

  useEffect(() => {
    const getProfileData = async () => {
      if(!session?.id){
        return;
      }

      if(isSessionExpired()){
        window.location.href = window.location.origin+'/login';
        return;
      }

      document.getElementById('userFullName').value = `${session.name} ${session.lastName}`;
      document.getElementById('userEmail').value = `${session.email}`;
    }

    if(!isSessionLoading){
      getProfileData();
      return;
    }
  }, [isSessionLoading]);

  let carrito = [
    {
      items: cart?.items?.map((item) => {
        return {
          name: item.title,
          description: item.description,
          quantity: item.qty,
          category: item.clasification,
          unit_amount: {
            currency_code: "MXN",
            value: item.price,
          },
        };
      }),
      amount: {
        currency_code: "MXN",
        value: (cart?.items?.reduce((total, item) => total + item.price * item.qty, 0) + ((shippingPrice || 0))).toFixed(2),
        breakdown: {
          item_total: {
            currency_code: "MXN",
            value: cart?.items?.reduce((total, item) => total + item.price * item.qty, 0).toFixed(2),
          },
          shipping: {
            currency_code: "MXN",
            value: (shippingPrice || 0).toFixed(2),
          },
        },
      },
      shipping: shippingAddress,
      description: "Compra en el sitio web de Los trapitos de Luca",
    },
  ];

  const EmptyCart = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">Carrito vacío</h4>
            <Link to="/product" className="btn btn-dark mx-4">
              <i className="fa fa-arrow-left"></i> Continuar comprando
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const onPaymentSuccess = () => {
    setModalContent(
      <div className="text-center text-small">
        Gracias por tu compra
      </div>
    );

    setIsPaymentSuccess(true);

    const modal = new Modal(document.getElementById('checkoutModal'));
    modal.show();
  };

  const onPaymentFail = (error) => {
    let message = "Error al intentar generar cobro";

    if (typeof error === "string") {
      message = error;
    }

    setModalContent(<>{message}</>);
    setIsPaymentSuccess(false);

    const modal = new Modal(document.getElementById('checkoutModal'));
    modal.show();
  };

  const ShowCheckout = () => {
    let subtotal = 0;
    let totalItems = 0;

    cart?.items?.map((item) => {
      return (subtotal += item.price * item.qty);
    });

    cart?.items?.map((item) => {
      return (totalItems += item.qty);
    });

    return (
      <>
        <div className="container py-0">
          <div className="row my-4">
            <div className="col-md-5 col-lg-4 order-md-last">
              <div className="card mb-4 shadow">
                <div className="card-header py-3 bg-light">
                  <h5 className="mb-0">Resumen de compra</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      Productos ({totalItems})<span>{formatMoney(Math.round(subtotal))}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      Costo de envío
                      <span>
                        {shippingPrice === null ? '--.--' : formatMoney(Math.round(shippingPrice))}
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <div>
                        <strong>Total:</strong>
                      </div>
                      <span>
                        <strong>{formatMoney(Math.round(subtotal + shippingPrice))}</strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-8">
              {shippingPrice === null ? (
                <div className="card mb-4 shadow">
                  <div className="card-header py-3">
                    <h4 className="mb-0">Información de envío</h4>
                  </div>
                  <div className="card-body">
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-10 col-xl-6">
                        <form autocomplete='off' onSubmit={() => onSubmitShippingAddress()}>
                          <div className="form my-3">
                            <div className="form my-3">
                              <label for="Name">Nombre Completo</label>
                              <input
                                type="string"
                                className="form-control"
                                id="userFullName"
                                placeholder="Escribe tu nombre"
                                required
                              />
                              <label for="Name">Correo electrónico</label>
                              <input
                                type="userEmail"
                                className="form-control"
                                id="userEmail"
                                placeholder="Email"
                                required
                              />
                              <label for="Name">Calle y Numero</label>
                              <input
                                type="string"
                                className="form-control"
                                id="street"
                                placeholder="Calle y Numero"
                                required
                              />
                              <div className="form my-3">
                                <label for="Name">Codigo Postal</label>
                                <input
                                  className="form-control"
                                  id="postalCode"
                                  placeholder="Código Postal"
                                  required
                                  type="number"
                                  min={9999}
                                  maxLength={99999}
                                />
                                <label for="Name">Colonia</label>
                                <input
                                  type="string"
                                  className="form-control"
                                  id="suburb"
                                  placeholder="Colonia"
                                  required
                                />
                                <div className="form my-3">
                                  <label for="Name">Ciudad</label>
                                  <input
                                    className="form-control"
                                    id="city"
                                    placeholder="Ciudad"
                                    required
                                  />
                                  <label for="estado">Estado:</label>
                                  <select id="state" name="estado" class="form-control" required>
                                    <option value="Aguascalientes">Aguascalientes</option>
                                    <option value="Baja California">Baja California</option>
                                    <option value="Baja California Sur">Baja California Sur</option>
                                    <option value="Campeche">Campeche</option>
                                    <option value="Chiapas">Chiapas</option>
                                    <option value="Chihuahua">Chihuahua</option>
                                    <option value="Coahuila">Coahuila</option>
                                    <option value="Colima">Colima</option>
                                    <option value="Durango">Durango</option>
                                    <option value="Guanajuato">Guanajuato</option>
                                    <option value="Guerrero">Guerrero</option>
                                    <option value="Hidalgo">Hidalgo</option>
                                    <option value="Jalisco">Jalisco</option>
                                    <option value="Estado de México">Estado de México</option>
                                    <option value="Michoacán">Michoacán</option>
                                    <option value="Morelos">Morelos</option>
                                    <option value="Nayarit">Nayarit</option>
                                    <option value="Nuevo León">Nuevo León</option>
                                    <option value="Oaxaca">Oaxaca</option>
                                    <option value="Puebla">Puebla</option>
                                    <option value="Querétaro">Querétaro</option>
                                    <option value="Quintana Roo">Quintana Roo</option>
                                    <option value="San Luis Potosí">San Luis Potosí</option>
                                    <option value="Sinaloa">Sinaloa</option>
                                    <option value="Sonora">Sonora</option>
                                    <option value="Tabasco">Tabasco</option>
                                    <option value="Tamaulipas">Tamaulipas</option>
                                    <option value="Tlaxcala">Tlaxcala</option>
                                    <option value="Veracruz">Veracruz</option>
                                    <option value="Yucatán">Yucatán</option>
                                    <option value="Zacatecas">Zacatecas</option>
                                  </select>
                                  <div className="form my-3">
                                    <label for="Email">Comentarios</label>
                                    <textarea
                                      className="form-control"
                                      id="comments"
                                      placeholder="Comentarios"
                                    />
                                  </div>
                                  <div className="row justify-content-center">
                                    <div className="col-auto">
                                      <button type="submit" class="text-aling-center btn btn-primary btn-sm">
                                        Continuar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              ): (
                <div className="card mb-4 shadow">
                  <div className="card-header py-3">
                    <h4 className="mb-0">Opciones de pago</h4>
                  </div>
                  <div className="card-body">
                    <form className="needs-validation" noValidate>
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-xl-6">
                          <div id="paypal-button-container">
                            <PayPalScriptProvider options={initialOptions}>
                              <PayPalButtons
                                style={{
                                  layout: "vertical",
                                }}
                                createOrder={async () => {
                                                              
  
                                  validateStock();
                                  
                                  
                                  try {
                                    const response = await fetch(
                                      "https://desarrollozakamura.com/los-trapitos-de-luca/api/?action=api-paypal",
                                      {
                                        method: "POST",
                                        headers: { 
                                          "API_KEY": '4f36725505999d31bf4f55e04c5f06679f78a633',
                                        },
                                        body: JSON.stringify({
                                          service: 'order',
                                          cart: carrito,
                                        }),
                                      }
                                    ); 
                                    const orderData = await response.json();
                                    if (orderData.id) {
                                      return orderData.id;
                                    } else {
                                      const errorDetail = orderData?.details?.[0];
                                      const errorMessage = errorDetail
                                        ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                        : JSON.stringify(orderData);
                            
                                      throw new Error(errorMessage);
                                    }
                                  } catch (error) {
                                    console.error(error);
                                    onPaymentFail(`Ocurrio un error al preparar la transacción, por favor contactanos`);   
                                  }
                                }}
                                onApprove={async (data) => {
                                  validateStock();

                                  setIsPaymentOnProgress(true);

                                 
                                  try {
                                    const response = await fetch(
                                      "https://desarrollozakamura.com/los-trapitos-de-luca/api/?action=api-paypal",
                                      {
                                        method: "POST",
                                        headers: { 
                                          "API_KEY": '4f36725505999d31bf4f55e04c5f06679f78a633',
                                        },
                                        body: JSON.stringify({
                                          orderId: data.orderID,
                                          service: 'capture'
                                        }),
                                      }
                                    );
                                    const orderData = await response.json(); 
                                    const errorDetail = orderData?.details?.[0];

                                    if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                      return data.restart();
                                    } else if (errorDetail) {
                                      throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                                    } else if (!orderData.purchase_units) {
                                      throw new Error(JSON.stringify(orderData));
                                    }

                                    await addTransaction(session.id || 'ventaPublico', data.orderID, carrito[0]);

                                    try {
                                      const response = await fetch(
                                        "https://desarrollozakamura.com/los-trapitos-de-luca/api/?action=email",
                                        {
                                          method: "POST",
                                          headers: { 
                                            "API_KEY": '4f36725505999d31bf4f55e04c5f06679f78a633',
                                          },
                                          body: JSON.stringify({
                                            service: 'order',
                                            cart: carrito,
                                            customer: customerInfo,
                                        }),
                                        }
                                      ); 
                                      const arrayItems = cart.items;

                                      for (let index = 0; index < arrayItems.length; index++) {
                                        const itemShop = arrayItems[index];
                                         
                                        let itemId = itemShop.id.split('#')[0];
                                        const productStock = await productInventory(itemId);                                   
                                        const diffStock = productStock - itemShop.qty;
                                      
    
                                        updateProductInventory(itemId,diffStock);
    
                                        };
                                      console.log(response)
                                    } catch (error) {
                                      console.log(error)
                                    }

                                    setIsPaymentOnProgress(false);
                                    onPaymentSuccess();

                                    return orderData;
                                  } catch (error) { 
                                    setIsPaymentOnProgress(false);
                                    onPaymentFail(`Lo sentimos, tu transaccion no pudo ser procesada`);
                                  }
                                }}
                                onError={(err) => {
                                  console.log(err)
                                  setIsPaymentOnProgress(false);
                                  onPaymentFail('Algo fallo al intentar realizar la compra');
                                }}
                              />
                            </PayPalScriptProvider>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const successContent = (
    <div className="container py-0">
      <div className="row my-4">
        <div className="col-12">
          <div class="alert alert-info text-center" role="alert" style={{ fontSize: 35, padding: 40 }}>
            ¡Gracias por tu compra! <i class="bi bi-bag-check-fill"></i>
          </div>
        </div>
      </div>
    </div>
  );

  const Content = () => {
    const mainContent = isPaymentSuccess ? successContent : <ShowCheckout />;

    return cart?.items?.length ? mainContent : <EmptyCart />;
  }

  return (
    <>
      <Navbar />
      <div className="modal fade" id="checkoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body" style={{ padding: '10px 12px 20px' }}>
              <CheckoutModal
                title={isPaymentSuccess ? 'Transacción exitosa' : 'Transacción fallida'}
                subtitle={isPaymentSuccess ? '¡Be a Legend!' : '¡Oh, algo salió mal!'}
                content={modalContent}
                description={isPaymentSuccess ? purchaseSuccessMsg : purchaseErrorMsg}
                type={isPaymentSuccess ? 'success' : 'error'}
              />
              <div className='row my-2'>
                {isPaymentSuccess ? (
                  <div className="col text-center">
                    <button
                      className={`btn btn-success`}
                      onClick={() => window.location.href = window.location.origin + "/product"}
                    >
                      Volver a la tienda
                    </button>
                  </div>
                ) : (
                  <div className="col text-center">
                  <button id="btnCloseModal" className={`btn btn-secondary`} data-bs-dismiss="modal">
                      Entendido
                    </button>  
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="OutStockModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body" style={{ padding: '10px 12px 20px' }}>
            <h1 className="text-center">Articulos agotados:</h1>
            <div className="card-body" style={{ background: 'rgb(233 233 233)' }}>
                    {cartOutStock?.map((item) => {
                      return (
                        <div>
                          
                            <div
                              key={item.id}
                              className='shadow m-4 p-4 rounded rounded-4'
                              style={{
                                background: '#fff',
                                minHeight: 260,
                                alignItems: 'center',
                                display: 'grid',
                              }}
                            >
                               <div className='row text-center justify-content-center'>
                                    <div className='col-12'>
                                    <h6 className='text-center'>{item.title}</h6>

                                    </div>
                                  </div>
                              <div className="row d-flex align-items-center">
                                <div className="">
                                  <div className="row text-center justify-content-center" data-mdb-ripple-color="light">
                                    <img
                                      src={item.image}
                                      alt={item.title}
                                      width={175}
                                      height={175}
                                     style={{maxWidth:'50%'}}
                                    />
                                    <div className='row text-center justify-content-center'>
                                      
                                      <div className='col-12 text-center justify-content-center'>
                                        <button className="btn px-3 disabled" style={{ border: "none" }}>
                                          <b>Se quitarán: {item.qty} pz(s)</b>
                                        </button>
                                      </div>
                                    
                                 
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-8 col-md-12 text-center order-1 order-lg-2">
                                 
                                </div>
                              </div>
                            </div>
                          
                        </div>
                      );
                    })}
                  </div>
              <div className='row my-2'>
                
                  <div className="col text-center">
                    <button id="btnCloseOutStockModal" className={`btn btn-secondary`} data-bs-dismiss="modal" onClick={() => ocultarModal()}>
                      Volver al carrito
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container my-3 py-3">
        <h1 className="text-center">Checkout</h1>
        <hr />
        {isPaymentOnProgress ? (
          <div className="container py-0">
            <div className="row my-4">
              <div className="col-12">
                <div className="card mb-4 shadow text-center">
                  <button className="btn" type="button" disabled style={{ width: '100%', padding: 40 }}>
                    <span className='mx-2' role="status" style={{ fontSize: 35 }}>
                      Terminando de procesar compra
                    </span>
                    <div class="spinner-border mx-2" role="status">
                      <span class="visually-hidden" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Content />
        )}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
