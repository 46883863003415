import React from "react"; 
import { Navbar, Footer } from "../components";
import img1 from "../elements/guia/medidas-mangs-larga-YBgl3J78BzcR8vZ4.avif"
import img2 from "../elements/guia/medidas-sueter-mnln10W6jMcMbMVp.avif"
import img3 from "../elements/guia/medidas-t-shirt-YBgl3J78Naf4KGO0.avif"
const PagosEnvios = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-md-10 col-xl-8 mb-3">
              <h3 className="my-5">Pagos Y Envios</h3>
              <h4 className="my-5">PAGOS</h4>
              <p>
                En nuestra tienda ofrecemos diferentes opciones de pago para que puedas elegir la que más te convenga. Puedes pagar con tarjeta de crédito o débito, lo cual te brinda comodidad y seguridad al realizar tu compra. Si prefieres pagar en efectivo, también aceptamos pagos en contraentrega en nuestros envios locales, para que puedas recibir y pagar tus productos al momento de la entrega. Además, si te resulta más conveniente, también puedes realizar un depósito en cualquier tienda Oxxo. Queremos facilitarte el proceso de compra y asegurarnos de que encuentres la opción de pago que se ajuste a tus necesidades. ¡Compra con confianza y disfruta de nuestros productos!
              </p>
              <h4 className="my-5">ENVIOS</h4>
              <p>
              En nuestra marca, nos encargamos de ofrecer envíos garantizados a nuestros clientes. Contamos con una red de locales propios, donde nos encargamos de realizar los repartos de manera eficiente y segura. Además, también realizamos envíos nacionales a través de servicios de paquetería confiables. Nuestro objetivo es asegurarnos de que cada producto llegue a su destino en perfectas condiciones y en el menor tiempo posible. Trabajamos arduamente para brindar un servicio de calidad a nuestros clientes, ofreciendo diferentes opciones de envío para adaptarnos a sus necesidades. Siempre nos esforzamos por superar las expectativas y garantizar la satisfacción de nuestros clientes. 
              </p>
            </div> 
          </div>
        </div>
      </div>  
      <Footer />
    </>
  );
};

export default PagosEnvios;
