import React from 'react'
import { Footer, Navbar } from "../components";
import img1 from "../elements/about/img_1441-ALpBqzpGpnUVObXX.avif"
import Home4 from "../elements/img/Home4.jpg";
import childs from "../elements/img/childs.webp";

const AboutPage = () => {


  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">¡Bienvenidos a Trapitos de Luca!</h1>
        <hr style={{ Color: "#c19ec8" }} />
        <div class="container-xxl py-4">
          <div className="row">
            <div className="col-6 text-center">
              <p>
              En Trapitos de Luca, nos enorgullece presentar una experiencia única en el mundo de la moda infantil. 
              Somos mucho más que una tienda; somos un rincón donde la calidad, el diseño encantador y la accesibilidad 
              se fusionan para vestir a los más pequeños con elegancia y comodidad.
              </p>
            </div>
          </div>
        </div>
        <div class="rounded" style={{ backgroundColor: "#c19ec8" }}>
          <div class="row g-0">
            <div
              class="col-lg-6 wow fadeIn"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div class="position-relative h-100">
                <img
                  class="position-absolute w-100 h-100 rounded"
                  src={Home4}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div class="h-100 d-flex flex-column justify-content-center p-5">
                <h1 class="mb-4">
                Nuestra Historia
                </h1>
                <p class="mb-4">
                Desde nuestro inicio, nos hemos dedicado apasionadamente a proporcionar ropa de alta calidad para los más pequeños de la casa. 
                Fundada con el propósito de ofrecer opciones que combinen estilo y funcionalidad, Trapitos de Luca se ha convertido en un referente 
                en el universo de la moda infantil.
                </p>
                
              </div>
            </div>             
          </div>
         </div>
         <div class="rounded" style={{ backgroundColor: "#c19ec8" }}>
         <div class="row g-0" style={{marginTop: "30px"}}>
              <div
                class="col-lg-6 wow fadeIn"
                data-wow-delay="0.1s"
                style={{ minHeight: "400px" }}
              >
                <div class="position-relative h-100">
                  <img
                    class="position-absolute w-100 h-100 rounded"
                    src={childs}
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div class="h-100 d-flex flex-column justify-content-center p-5">
                  <h1 class="mb-4">
                  Nuestra Misión
                  </h1>
                  <p class="mb-4">
                  En Trapitos de Luca, creemos que cada niño merece vestirse con prendas que reflejen
                   su personalidad única. Nos esforzamos por ser más que una marca; somos cómplices en
                    las travesuras diarias, testigos de los momentos especiales y acompañantes en el 
                    crecimiento de los pequeños. Nuestra misión es brindar a los padres la confianza de
                     que sus hijos están vestidos con prendas que combinan durabilidad, estilo y encanto.
                  </p>
                  
                </div>
              </div>             
         </div>
         </div>

         <div class="container-xxl py-5">
            <div class="container">
              <div
                class="text-center mx-auto mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: "600px" }}
                >
                <h1 class="mb-3">¿Por Qué Elegir Trapitos de Luca?</h1>

              </div>
          </div>
          <div className="row g-4">
          <div className="col-lg-3 col-sm-6 wow fadeInUp" style={{ backgroundColor: "#ec2c5d", marginRight:'5px',marginTop:'5px' }}>
            <p className="lead text-center" style={{ color: "White" }}>       
            <p className="lead text-center" style={{ color: "White" ,fontWeight:'600',paddingTop:'20px' }}>              
            Calidad Inigualable: 
            </p>       
            Cada pieza de ropa en Trapitos de Luca está confeccionada
             con los más altos estándares de calidad. Utilizamos materiales suaves y duraderos para garantizar
              la comodidad de los niños mientras mantienen su estilo único.
            </p>
            
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" style={{ backgroundColor: "#ec2c5d", marginRight:'5px',marginTop:'5px' }}>
            <p className="lead text-center" style={{ color: "White" }}>
            <p className="lead text-center" style={{ color: "White" ,fontWeight:'600',paddingTop:'20px' }}>              
            Diseños Encantadores:
            </p>   
             Nuestro equipo de diseñadores se esfuerza por crear colecciones
             que cautiven la imaginación de los pequeños y reflejen las últimas tendencias de moda infantil.
              Desde estampados lúdicos hasta detalles cuidadosamente elaborados, cada prenda cuenta una historia.
            </p>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" style={{ backgroundColor: "#ec2c5d", marginRight:'5px',marginTop:'5px'}}>
            <p className="lead text-center" style={{ color: "White" }}>
            <p className="lead text-center" style={{ color: "White",fontWeight:'600',paddingTop:'20px' }}>              
            Accesibilidad sin Compromisos: 
            </p>   
             Creemos que la moda de calidad no debería romper el banco.
             En Trapitos de Luca, ofrecemos ropa de alta gama a precios accesibles para que los padres
              puedan brindar lo mejor a sus hijos sin sacrificar la calidad ni el estilo.
            </p>
          </div>
        </div>
        </div>
        <div class="container-xxl py-5">
            <div class="container">
              <div
                class="text-center mx-auto mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: "600px" }}
                >
                <p>
                Gracias por elegirnos para ser parte de los momentos especiales de tu familia.
                </p>
                <p>
                ¡Descubre la magia de vestir a tus pequeños con Trapitos de Luca!
                </p>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutPage