import React from "react"; 
import { Navbar, Footer } from "../components";
import img1 from "../elements/guia/medidas-mangs-larga-YBgl3J78BzcR8vZ4.avif"
import img2 from "../elements/guia/medidas-sueter-mnln10W6jMcMbMVp.avif"
import img3 from "../elements/guia/medidas-t-shirt-YBgl3J78Naf4KGO0.avif"
const GuiaTallas = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3">
              <img src={img1} alt="" className="img-fluid zoom rounded" data-bs-toggle="modal" data-bs-target="#exampleModal"/>
            </div>
            <div className="col-md-6 mb-3">
              <img src={img2} alt="" className="img-fluid zoom rounded"/>
            </div>
            <div className="col-md-6 mb-3">
              <img src={img3} alt="" className="img-fluid zoom rounded"/>
            </div>
          </div>
        </div>
      </div>
    <div> 
        {/* Modal */}
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body text-center">
                <img src={img1} alt="" className="m-auto img-fluid zoom rounded"/>
              </div> 
            </div>
          </div>
        </div>
      </div>
       <Footer />
    </>
  );
};

export default GuiaTallas;
