import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/auth";
import getCurrentDatetime from "../helpers/getCurrentDatetime";

const firebaseConfig = {
  apiKey: "AIzaSyAZ3XIXXAFBXDeLLElIIQm4QTTtaqO2A4E",
  authDomain: "trapitos-de-luka.firebaseapp.com",
  databaseURL: "https://trapitos-de-luka-default-rtdb.firebaseio.com",
  projectId: "trapitos-de-luka",
  storageBucket: "trapitos-de-luka.appspot.com",
  messagingSenderId: "259946196928",
  appId: "1:259946196928:web:915078314cd87b13cea225",
  measurementId: "G-464JHYGQ5S"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const getProducts = async () => {
  try {
    const respond = await firebaseApp.database().ref('Products').orderByChild('Status').equalTo(1).get();
    const products = respond.val();
    

  
    const productsFormated = Object.keys(products).map(key => {
      const images = products[key]['Images'] ? 
        Object.keys(products[key]['Images']).map(imgKey => products[key]['Images'][imgKey].Url) 
        : []; // Si no existe el nodo 'Images', se asigna un arreglo vacío
      // const colors = Object.keys(products[key] && products[key].Design.Colors).filter(colorKey => products[key].Design.Colors[colorKey] === true);

      const productFormat = {
        ...products[key],
        Age: products[key].Age + ' Años',
        Images: images,
        image: images.length > 0 ? images[0] :null,
        Colors: [],
      }
  
      return productFormat;
    }).filter(product => product.image !== null); // Filtra los productos que no tienen imagen
    
    return productsFormated.filter(product => product.Pzs >= 1);
  } catch (error) {
    throw (error);
  }
}

export const userLogin = async (data) => {
  try {
    const userSigned = await firebaseApp.auth().signInWithEmailAndPassword(data.email, data.pass);

    const uid = userSigned?.user?.uid;
    
    if(!uid){
      throw new Error('Error en autenticación');
    }
    
    const respond = await firebaseApp.database().ref('Customers/'+uid).get();
    const customer = respond.val();

    if(!customer?.FId){
      throw new Error('Credenciales incorrectas');
    }
  
    return customer;
  } catch (error) {
    throw (error);
  }
}

export const userRegistration = async (data) => {
  try {
    const respond = await firebaseApp.auth().createUserWithEmailAndPassword(data.email, data.pass);
    const fid = respond.user.uid; 
    
    const FechaCompleta = getCurrentDatetime();

    await firebaseApp.database().ref('Customers/'+fid).set({
      "Status": "Active",
      "Name": data.name,
      "LastName": data.lastName,
      "Email": data.email,
      "Tel": data.phone,
      "FId": fid,
      "Partner": false,
      "PartnerCard": "",
      "CreationDate": FechaCompleta,
      "UpdatedDate": FechaCompleta,
    });
  } catch (error) {
   console.log(error); 
  }
}

export const getPostalCodes = async (postalCode) => {
  try {
    const respond = await firebaseApp.database().ref('PostalCodes').orderByChild('PostalCode').equalTo(Number(postalCode)).get();
    const postalCodes = respond.val();

    const postalCodesFormated = Object.keys(postalCodes).map(postalCodeKey => postalCodes[postalCodeKey]);

    return postalCodesFormated;
  } catch (error) {
    return [];
  }
};

export const getCardsByCustomerId = async (customerId) => {
  try {
    const respond = await firebaseApp.database().ref('Cards/'+customerId).get();
    const cards = respond.val();

    const cardsFormated = Object.keys(cards).map(cardKey => cards[cardKey]);

    return cardsFormated || [];
  } catch (error) {
    return [];
  }
};

export const getTransactionsByCustomerId = async (customerId) => {
  try {
    const respond = await firebaseApp.database().ref('Transactions/'+customerId).get();
    const transactions = respond.val();

    const transactionsFormated = Object.keys(transactions).map(transactionKey => ({
      ...transactions[transactionKey],
      id: transactionKey,
    }));

    console.log('transactionsFormated', transactionsFormated);

    return transactionsFormated;
  } catch (error) {
    return [];
  }
};

export const addTransaction = async (customerId, paypalOrderId, transaction) => {
  try {
    const FechaCompleta = getCurrentDatetime();

    const respond = await firebaseApp.database().ref('Transactions/'+customerId).push({
      ...transaction,
      paypalOrderId,
      creationDate: FechaCompleta,
    });

    console.log('respond', respond);
  } catch (error) {
    throw error;
  }
}

export const productInventory = async (productId) => {
  try {
    const respond = await firebaseApp.database().ref('Products/'+productId).get();
    const product = respond.val();
    return product.Pzs;
  } catch (error) {
    throw error;
  }
}


export const updateProductInventory = async (productId, productPzs) => {
  try {
    
    const respond = await firebaseApp.database().ref('Products/'+productId).update({"Pzs":productPzs});

   // const product = respond.val();
   // return product.Pzs;
  } catch (error) {
    throw error;
  }
}

export default getProducts;
