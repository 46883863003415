import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Alert } from "../components";
import formatMoney from "../helpers/formatMoney.js";
import { Modal } from 'bootstrap';
import ProductSelectModal from "./ProductSelectModal";
import { getProducts } from "../services/FirebaseService";
import ECategories from "../enums/ECategories"
import useCart from '../hooks/useCart';

const Products = () => {
  const [alert, setAlert] = useState(null);
  const showAlert = (type, message) => {
    setAlert({ type, message });
  };
  const closeAlert = () => {
    setAlert(null);
  };

  const [searchParams] = useSearchParams();

  const { addCartItem } = useCart();

  const [filterSelected, setFilterSelected] = useState(null);

  const [currentProduct, setCurrentProduct] = useState(null);
  const [productsState, setProductsState] = useState([]);
  const [productsFiltered, setProductsFiltered] = useState(productsState);

  const [loading, setLoading] = useState(false);
  const [esDispositivoMovil, setEsDispositivoMovil] = useState(false);

  let componentMounted = true;

  const onClikcAddProduct = (product) => {
    setCurrentProduct(product);

    const modal = new Modal(document.getElementById('checkoutModal'));
    modal.show();
  }
  
  const addToCart = (item) => {
    addCartItem(item);
    document.getElementById('btnCloseModal').click();
    showAlert('success', 'Artículo agregado al carrito de compra');
    setTimeout(closeAlert, 2000);
  }

  function goToCustom(){
    window.location.href = window.location.origin+'/custom';
  }
  useEffect(() => {
    const verificarEsMovil = () => {
      setEsDispositivoMovil(window.innerWidth < 768);
    };

    window.addEventListener('resize', verificarEsMovil);

    verificarEsMovil();

    return () => {
      window.removeEventListener('resize', verificarEsMovil);
    };
  }, []);
// Función para generar el contenido de la tarjeta
const renderCard = (product) => (
  <div id={product.Id} key={product.Id} className="col-6 col-sm-6 col-md-4 col-lg-3 p-0 shadow">
    <div className="card text-center h-100" key={product.Id} style={{ cursor: 'pointer' }} onClick={() => onClikcAddProduct(product)}>
      <img
        className="card-img-top p-3"
        src={product.image}
        alt="Card"
        height={300}
        style={{ objectFit: 'cover' }}
      />
      <div className="card-body">
        <b className="card-title d-block" style={{ minHeight: 50, maxHeight: 50, overflow: 'hidden' }}>
          {product.Name}
        </b>
        {product.Pzs === 1 ? (
          <p className="card-text p-2" style={{ background: '#ffcccc' }}>
            Única Pieza
          </p>
        ) : (
          <p className="card-text p-2" style={{ background: '#e5e5e5' }}>
            Piezas disponibles: <b>{product.Pzs}</b>
          </p>
        )}
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item lead list-card">{formatMoney(product.Price)}</li>
      </ul>
      <div className="card-body">
        <button className="btn w-100 btn-light m-1 btn-add-cart">
          Agregar a carrito <i className="fa fa-cart-shopping mr-1"></i>
        </button>
      </div>
    </div>
  </div>
);

  useEffect(() => {
    const setProducts = async () => {
      setLoading(true);
      const products = await getProducts();
      console.log('products', products);

      const designsIds = new Set();
      const designs = [];

      for (const product of products) {
        if (!designsIds.has(product.Design?.Id)) {
          designsIds.add(product.Design?.Id);
          designs.push(product.Design);
        }
      }

      console.log('designs', designs);
      
      if (componentMounted) {
        setProductsState(products);

        const categorySelected = searchParams.get('category');
        const categoryAvailable = Object.values(ECategories).find(
          category => categorySelected?.toUpperCase() === category?.toUpperCase()
        );

        if(categoryAvailable){
          setFilterSelected(categoryAvailable);
          setProductsFiltered(products.filter((item) => item.Category === categoryAvailable));
        }else{
          setProductsFiltered(products);
        }

        setLoading(false);
      }

      return () => {
        componentMounted = false;
      };
    };

    setProducts();

    return () => {
      const modalScreen = document.getElementsByClassName('modal-backdrop');

      if(modalScreen[0]){
        modalScreen[0].remove();
        document.body.classList.remove('modal-open');
        document.body.removeAttribute('style');
      }
    };
  }, []);

  const Loading = () => {
    return (
      <div className="row">
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={400} />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={400} />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={400} />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={400} />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={400} />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={400} />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={400} />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={400} />
        </div>
      </div>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = productsState.filter((item) => item.Category === cat);
    setProductsFiltered(updatedList);
    setFilterSelected(cat);
  }
  const ShowProducts = () => {
    return (
      <>
        <div className="buttons text-center py-5">
          <button className={`btn ${filterSelected === null ? 'btn-dark' : 'btn-outline-dark'} btn-sm m-2`} onClick={() => {setProductsFiltered(productsState); setFilterSelected(null)}}>Todos</button>
          <button className={`btn ${filterSelected === ECategories.Boy ? 'btn-dark' : 'btn-outline-dark'} btn-sm m-2`} onClick={() => filterProduct(ECategories.Boy)}>Niños</button>
          <button className={`btn ${filterSelected === ECategories.Girl ? 'btn-dark' : 'btn-outline-dark'} btn-sm m-2`} onClick={() => filterProduct(ECategories.Girl)}>Niñas</button>
          <button className={`btn ${filterSelected === ECategories.Unisex ? 'btn-dark' : 'btn-outline-dark'} btn-sm m-2`} onClick={() => filterProduct(ECategories.Unisex)}>Unisex</button>
          {/*
            <button className={`btn btn-warning btn-sm m-2`} onClick={() => goToCustom()}>Personaliza tu prenda</button>
          */}
        </div>

        <div className="row m-4">
          {esDispositivoMovil ? (
productsFiltered.map((product) => {
  return (

    
    <div id={product.Id} key={product.Id} className="col-6 col-sm-6 col-md-4 col-lg-3 p-0 shadow">
      <div className="card text-center h-100" key={product.Id} style={{ cursor: 'pointer' }} onClick={() => onClikcAddProduct(product)}>
        <img
          className="card-img-top p-3"
          src={product.image}
          alt="Card"
          height={300}
          style={{ objectFit: 'cover' }}
        />
        <div className="card-body">
          <b className="card-title d-block" style={{ minHeight: 50 , maxHeight:50,overflow:'hidden' }}>
            {product.Name}
          </b>
          {product.Pzs === 1 ? (
            <p className="card-text p-2" style={{ background: '#ffcccc' }}>
              Única Pieza
            </p>
          ) : (
            <p className="card-text p-2" style={{ background: '#e5e5e5' }}>
              Piezas disponibles: <b>{product.Pzs}</b>
            </p>
          )}
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item lead list-card">{formatMoney(product.Price)}</li> 
        </ul>
        <div className="card-body">
          <button className="btn w-100 btn-light m-1 btn-add-cart">
            Agregar a carrito <i className="fa fa-cart-shopping mr-1"></i>
          </button>
        </div>
      </div>
    </div>


  );
})      ) : (
  productsFiltered.map((product) => {
    return (

      
      <div id={product.Id} key={product.Id} className="col-12 col-sm-6 col-md-4 col-lg-3 p-0 shadow">
        <div className="card text-center h-100" key={product.Id} style={{ cursor: 'pointer' }} onClick={() => onClikcAddProduct(product)}>
          <img
            className="card-img-top p-3"
            src={product.image}
            alt="Card"
            height={300}
            style={{ objectFit: 'cover' }}
          />
          <div className="card-body">
            <b className="card-title d-block" style={{ minHeight: 50 }}>
              {product.Name}
            </b>
            {product.Pzs === 1 ? (
              <p className="card-text p-2" style={{ background: '#ffcccc' }}>
                Única Pieza
              </p>
            ) : (
              <p className="card-text p-2" style={{ background: '#e5e5e5' }}>
                Piezas disponibles: <b>{product.Pzs}</b>
              </p>
            )}
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item lead list-card">{formatMoney(product.Price)}</li> 
          </ul>
          <div className="card-body">
            <button className="btn w-100 btn-light m-1 btn-add-cart">
              Agregar a carrito <i className="fa fa-cart-shopping mr-1"></i>
            </button>
          </div>
        </div>
      </div>


    );
  })      )}
        </div>
      </>
    );
  };
  return (
    <>
      <div className="modal fade" id="checkoutModal" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              {currentProduct?.Name}
              <button
                id="btnCloseModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body" style={{ padding: '10px 13px 20px' }}>
              <div className="row">
                <div className="col-12 p-0">
                  <ProductSelectModal
                    product={currentProduct}
                    onOk={(product) => addToCart(product)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">Nuestros Productos</h2>
            <hr />
          </div>
          <div className="col-12">
            {loading ? <Loading /> : <ShowProducts />}
          </div>
        </div>
      </div>
      
      {alert && (
        <div className='w-md-50' style={{top: "50%", left: "40%", position: "fixed"}}>
          <Alert type={alert.type} message={alert.message}/>
        </div> 
      )}
    </>
  );
};

export default Products;
