export const EColors = {
  Black: 'Black',
  White: 'White',
  Beige: 'Beige',
  Brown: 'Brown',
  CarbonGray: 'CarbonGray',
  MilitaryGreen: 'MilitaryGreen',
  RedWine: 'RedWine',
};

export const EColorsHex = {
  [EColors.Black]: '#000',
  [EColors.White]: '#fff',
  [EColors.Beige]: '#faeec4',
  [EColors.Brown]: '#7b4a31',
  [EColors.CarbonGray]: '#8c92ac',
  [EColors.MilitaryGreen]: '#66cc66',
  [EColors.RedWine]: '#d10e0e',
};

export default EColors;