import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ImagenLogo from '../elements/LogotipoTrapitos.png'
import useAuth from '../hooks/useAuth';
import useCart from '../hooks/useCart';

const Navbar = () => {
    const { session, loading } = useAuth();
    const { cart } = useCart();
    const [esDispositivoMovil, setEsDispositivoMovil] = useState(false);
    useEffect(() => {
        const verificarEsMovil = () => {
            setEsDispositivoMovil(window.innerWidth < 768);
        };

        window.addEventListener('resize', verificarEsMovil);

        verificarEsMovil();

        return () => {
            window.removeEventListener('resize', verificarEsMovil);
        };
    }, []);
    return (
        <nav style={{zIndex:"2"}} className="navbar shadow navbar-expand-lg navbar bg-color-heder">

            <div className="container" style={{display:'block'}}>
                <div className="row align-items-center">
                    <div className="col-6 col-md-6">
                        <NavLink className="navbar-brand fw-bold fs-4 px-2" to="/">
                            <img src={ImagenLogo} alt="Logotipo" width={120} height={100} style={{ height: 'auto' }} />
                        </NavLink>
                    </div>

                    <div className="col-3 col-md-2">
                        {esDispositivoMovil && (
                            <div className="buttons text-end" style={{ marginRight: '-30px' }} >
                                <NavLink to="/cart" className="btn btn-outline m-2">
                                    <i className="fa fa-cart-shopping mr-1" style={{ fontSize: '1.5em' }}></i> ({cart?.items?.length || 0})
                                </NavLink>
                            </div>
                        )}
                    </div>

                    <div className="col-3 col-md-4 text-end ">
                        <button className="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                       
                    </div>
                </div>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto my-2 text-center align-items-center">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/">Inicio </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/product">Productos</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/about">Nosotros</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/contact">Contacto</NavLink>
                                </li>
                                {/*
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/invest">
                                    <button className='btn btn-warning rounded-pill'>
                                        Hazte socio
                                    </button>
                                </NavLink>
                            </li>
                        */}
                                {session?.id ? (
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/profile">
                                            {loading && <span className="spinner-grow spinner-grow-sm" aria-hidden="true" />}
                                            <i className="bi bi-person-fill"></i> Mi cuenta
                                        </NavLink>
                                    </li>
                                ) : (
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/login">
                                            {loading && <span className="spinner-grow spinner-grow-sm" aria-hidden="true" />}
                                            <i className="bi bi-person-fill"></i> Inicia sesión
                                        </NavLink>
                                    </li>
                                )}
                            </ul>

                            {!esDispositivoMovil && (
                                <div className="buttons text-center">
                                    <NavLink to="/cart" className="btn btn-outline m-2">
                                        <i className="fa fa-cart-shopping mr-1"></i> ({cart?.items?.length || 0})
                                    </NavLink>
                                </div>
                            )}

                        </div>


            </div>

        </nav>
    )
}

export default Navbar